import React from 'react';

import styled from 'styled-components';

import EmptyLayoutIllustration from './Illustration.png';

const EmptyLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  padding: 24px 0px;

  p {
    color: #101828;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
`;

const EmptyLayout = (): React.ReactElement => (
  <EmptyLayoutWrapper>
    <img width={152} height={118} alt="EmptyLayoutIllustration" src={EmptyLayoutIllustration} />
    <p>Add section to start editing</p>
  </EmptyLayoutWrapper>
);

export default EmptyLayout;
