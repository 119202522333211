import React from 'react';

import DisableProduct from 'components/Icons/DisabledProduct';

import { UserLicensesResponse } from 'utils/api/types';

import ActiveProduct from 'components/Icons/ActiveProduct';

import { useStore } from 'utils/hooks/store';

import { ProductAccessContainer, ProductAccessText } from './styles';

const PRODUCT_CONFIGS = {
  withEvents: [
    { key: 'protectorAlarms', label: 'Lone Working' },
    { key: 'events', label: 'Events' },
    { key: 'workflows', label: 'Audits' },
  ],
  withoutEvents: [
    { key: 'protectorAlarms', label: 'Lone Working' },
    { key: 'protectorIncidents', label: 'Incidents' },
    { key: 'workflows', label: 'Audits' },
  ],
} as const;

const UserProductsCell: React.FunctionComponent<{ licenses: UserLicensesResponse }> = ({ licenses }) => {
  const { session } = useStore();

  const licensesToDisplay = session.hasEventsLicense ? PRODUCT_CONFIGS.withEvents : PRODUCT_CONFIGS.withoutEvents;

  return (
    <div style={{ display: 'flex' }}>
      {licensesToDisplay.map(({ key, label }) => (
        <ProductAccessContainer key={key}>
          {licenses[key as keyof UserLicensesResponse] ? <ActiveProduct /> : <DisableProduct />}
          <ProductAccessText>{label}</ProductAccessText>
        </ProductAccessContainer>
      ))}
    </div>
  );
};

export default UserProductsCell;
