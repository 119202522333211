import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
`;
