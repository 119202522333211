import styled from 'styled-components';

export const StyledRowTitle = styled.p`
  margin-right: 14px;
  margin-top: 0;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
`;

export const Value = styled.p`
  color: #101d31;
  text-align: left;
  font: normal normal normal 14px/20px Roboto;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
  opacity: 1;
  white-space: pre-line;
  word-break: break-word;
`;

export const StyledContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  gap: 10px;
`;
