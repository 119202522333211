/* eslint-disable max-len */
import * as React from 'react';

function DropdownIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.5" y="0.5" width="15" height="15" rx="4.2" stroke="black" strokeOpacity="0.54" />
      <path
        d="M11.06 5.53027L8 8.58361L4.94 5.53027L4 6.47027L8 10.4703L12 6.47027L11.06 5.53027Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export default DropdownIcon;
