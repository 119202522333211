import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'vatix-ui/lib/components/Button';

import { Formik, Form } from 'formik';

import { CircularProgress, MenuItem, Tooltip } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import { AxiosError } from 'axios';

import CustomModal from 'components/CustomModal';
import { StyledSelect, StyledTextField } from 'components/Input/styles';

import { DisplayProtectorType, EntityFieldRoles, EntityFieldType, ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import { ButtonsWrapper, FieldHeader, StyledModalActions } from './styles';

import DropdownOptions from '../DropdownOptions';
import {
  EntityFieldTypeWithoutUUID,
  EntityFiledValidationSchema,
  getBorderStyle,
  modifyInputValue,
  possibleProtectorTypes,
} from './types';
import { SortableItemType } from '../DropdownOptions/types';
import StagesOptions from '../StagesOptions';
import { StageItemType } from '../StagesOptions/types';

const SubmitButton: React.FC<{ isSubmitting: boolean; isResetting: boolean; editView: boolean; editable: boolean }> = ({
  isResetting,
  isSubmitting,
  editView,
  editable,
}) => (
  <Button
    id="create-field-button"
    variant="contained"
    size="large"
    type="submit"
    disabled={isSubmitting || isResetting || !editable}
    style={{ marginLeft: '8px' }}
  >
    {isSubmitting && <CircularProgress color="inherit" size="16px" style={{ marginRight: '8px' }} />}
    {editView ? 'Save' : 'Create'}
  </Button>
);

const CancelButton: React.FC<{ handleClose: () => void; editView: boolean }> = ({ handleClose, editView }) => (
  <Button
    id="cancel-button"
    variant={editView ? 'outlined' : 'text'}
    size="large"
    onClick={handleClose}
    data-testid="cancelButton"
  >
    Cancel
  </Button>
);

const CreateEditFieldModal: React.FunctionComponent<{
  entityType: string;
  editedValues: EntityFieldType;
  onClose: (refresh: boolean) => void;
}> = ({ entityType, editedValues, onClose }): React.ReactElement => {
  const {
    entityFields: { editField, createField },
    notification,
    session: { user },
  } = useStore();
  const editView = editedValues.uuid !== '';

  const [isResetting, setIsResetting] = React.useState(false);
  const [multipleAnswers, setMultipleAnswers] = React.useState(
    editedValues.protectorType === ProtectorType.MultiChoice
  );
  const [options, setOptions] = React.useState<SortableItemType[]>(
    editedValues.properties?.enum?.map((o) => ({ ...o, displayKey: o.key, closed: o.closed })) || []
  );

  const updateOptions = (newOptions: SortableItemType[]): void => {
    setOptions(newOptions);
  };

  const isDropdownOption = (protectorType: string): boolean =>
    protectorType === ProtectorType.SingleChoice || protectorType === ProtectorType.MultiChoice;

  const prepareData = (values: EntityFieldTypeWithoutUUID): EntityFieldTypeWithoutUUID => {
    let isStage = false;
    // prepare values for stages
    if (values.properties?.roles?.includes(EntityFieldRoles.STAGE)) {
      isStage = true;
      // Sort the options to put closed items at the end
      const sortedOptions = [...options].sort((a, b) => {
        if (a.closed && !b.closed) return 1;
        if (!a.closed && b.closed) return -1;
        return 0;
      });

      values.properties = {
        enum: sortedOptions.map(({ displayKey, value, active, closed }) => ({
          key: displayKey,
          value,
          active,
          closed: closed || false,
        })),
      };

      values.properties.roles = [...(values.properties?.roles || []), EntityFieldRoles.STAGE];
    }

    if (values.protectorType === ProtectorType.SingleChoice && multipleAnswers && !isStage) {
      values.protectorType = ProtectorType.MultiChoice;
    }
    if (
      (values.protectorType === ProtectorType.MultiChoice || values.protectorType === ProtectorType.SingleChoice) &&
      !isStage
    ) {
      // modify options for sending, key should have the value of displayKey and displayKey should be removed
      values.properties = {
        enum: options.map(({ displayKey, value, active, closed }) => ({ key: displayKey, value, active, closed })),
      };
    }
    // if the values are not lookup type, remove the lookupType property
    if (values.protectorType !== ProtectorType.Lookup && values.properties && values.properties.lookupType === '') {
      delete values.properties;
    }
    // modify the key value: add a prefix with entity type
    // add prefix only if it is not already present
    if (!values.key.startsWith(`${entityType}__`)) values.key = `${entityType}__${values.key}`;

    return values;
  };

  const handleCreate = async (values: EntityFieldTypeWithoutUUID, resetForm?: () => void): Promise<void> => {
    try {
      const dataToSubmit = prepareData(values);
      await createField(entityType, dataToSubmit);

      if (resetForm) {
        resetForm();
      } else {
        onClose(true);
      }

      // reset options
      setOptions([]);

      notification.enqueueSuccessSnackbar('Field created successfully');
    } catch (e) {
      const error = e as AxiosError;
      notification.enqueueErrorSnackbar(error.response?.data[0] || 'Failed to create field');
    } finally {
      setIsResetting(false);
    }
  };

  const handleEdit = async (values: EntityFieldTypeWithoutUUID, editedVal: EntityFieldType): Promise<void> => {
    try {
      const dataToSubmit = prepareData(values);
      await editField(entityType, editedVal.uuid, dataToSubmit);
      notification.enqueueSuccessSnackbar('Field edited successfully');
      onClose(true);
    } catch (e) {
      const error = e as AxiosError;
      notification.enqueueErrorSnackbar(error.response?.data[0] || 'Failed to edit field');
    } finally {
      setIsResetting(false);
    }
  };

  const handleSubmit = async (values: EntityFieldTypeWithoutUUID, resetForm?: () => void): Promise<void> => {
    if (resetForm) setIsResetting(true);
    if (editView) {
      await handleEdit(values, editedValues);
    } else {
      await handleCreate(values, resetForm);
    }
  };

  // @ts-expect-error
  const initialValues: EntityFieldTypeWithoutUUID = editView
    ? { ...editedValues }
    : {
        protectorType: '',
        properties: {
          lookupType: '',
        },
        name: '',
        key: '',
        description: '',
      };

  const handleClose = (refresh: boolean, resetForm?: () => void): void => {
    if (resetForm) {
      resetForm();
    }
    onClose(refresh);
  };

  return (
    <CustomModal openModal onClose={() => handleClose(false)} modalTitle={editView ? 'Edit Field' : 'Create Field'}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={EntityFiledValidationSchema}
      >
        {({ values, handleChange, setFieldValue, isSubmitting, errors, touched, resetForm }) => (
          <Form>
            <FieldHeader>Field Label</FieldHeader>
            <StyledTextField
              fullWidth
              id="name"
              name="name"
              placeholder="Field Label"
              value={values.name}
              onChange={(event) => {
                handleChange(event);
                if (editView) return;
                const keyVal = modifyInputValue(event.target.value);
                setFieldValue('key', keyVal);
              }}
              error={Boolean(errors.name && touched.name)}
              disabled={editView && !values.editable}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FieldHeader>API Name</FieldHeader>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: '#fff',
                      margin: 0,
                      padding: '4px 8px',
                      fontSize: '14px',
                      borderRadius: '4px',
                      fontWeight: 400,
                    },
                  },
                }}
                title="Used to read from and write to the field from the API"
                placement="right"
              >
                <InfoIcon fontSize="small" style={{ margin: '16px 0 8px 6px', color: 'rgba(0, 0, 0, 0.54)' }} />
              </Tooltip>
            </div>
            <StyledTextField
              fullWidth
              id="key"
              name="key"
              placeholder="API Name"
              // remove the prefix from the key value
              value={values.key.replace(`${entityType}__`, '')}
              onChange={handleChange}
              disabled={editView}
              error={Boolean(errors.key && touched.key)}
              InputProps={{
                startAdornment: <p style={{ color: '#666' }}>{entityType}__</p>,
              }}
            />
            <FieldHeader>Description</FieldHeader>
            <StyledTextField
              fullWidth
              id="description"
              name="description"
              multiline
              maxRows={3}
              placeholder="Description"
              value={values.description}
              onChange={handleChange}
              disabled={editView && !values.editable}
            />

            {/* if in edit view and type is dropdown display warning message */}
            <FieldHeader>Field Type</FieldHeader>
            <StyledSelect
              size="small"
              displayEmpty
              fullWidth
              id="protectorType"
              name="protectorType"
              placeholder="Choose an Option"
              value={
                values.protectorType === ProtectorType.MultiChoice ? ProtectorType.SingleChoice : values.protectorType
              }
              onChange={handleChange}
              disabled={editView}
              error={Boolean(errors.protectorType && touched.protectorType)}
              $withoutBorder
              style={{
                color: values.protectorType ? 'rgb(0,0,0)' : 'rgba(0, 0, 0, 0.7)',
                border: getBorderStyle(editView, errors, touched),
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    minWidth: '552px !important',
                  },
                },
              }}
              renderValue={(value) => {
                if (values.properties && (values.properties.roles || []).includes(EntityFieldRoles.STAGE)) {
                  return 'Stage';
                }
                return value === ProtectorType.SingleChoice ? 'Dropdown' : DisplayProtectorType[value as ProtectorType];
              }}
            >
              <MenuItem value="" disabled>
                Choose an Option
              </MenuItem>
              {possibleProtectorTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type === ProtectorType.SingleChoice ? 'Dropdown' : DisplayProtectorType[type]}
                </MenuItem>
              ))}
            </StyledSelect>
            {/* display content for stages */}
            {values.properties && (values.properties.roles || []).includes(EntityFieldRoles.STAGE) && (
              <StagesOptions options={options as StageItemType[]} setOptions={updateOptions} />
            )}

            {isDropdownOption(values.protectorType) && !values.properties?.roles?.includes(EntityFieldRoles.STAGE) ? (
              <DropdownOptions
                editView={editView}
                options={options}
                setOptions={updateOptions}
                multipleAnswers={multipleAnswers}
                setMultipleAnswers={setMultipleAnswers}
              />
            ) : null}

            {values.protectorType === ProtectorType.Lookup && (
              <>
                <FieldHeader>Related to</FieldHeader>
                <StyledSelect
                  displayEmpty
                  fullWidth
                  id="properties.lookupType"
                  name="properties.lookupType"
                  placeholder="Related to"
                  value={values.properties && values.properties.lookupType}
                  onChange={handleChange}
                  disabled={editView}
                  error={Boolean(errors.properties && touched.properties)}
                  $withoutBorder
                  style={{
                    color: values.properties ? 'rgb(0,0,0)' : 'rgba(0, 0, 0, 0.7)',
                    border: getBorderStyle(editView, errors, touched),
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        minWidth: '552px !important',
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Choose an Option
                  </MenuItem>
                  {user?.activeEntityModules.map((type) => (
                    <MenuItem key={type} value={type}>
                      {getEntityTranslation[type].singular}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </>
            )}

            {editView ? (
              <ButtonsWrapper>
                <CancelButton handleClose={() => handleClose(false, resetForm)} editView={editView} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  isResetting={isResetting}
                  editView={editView}
                  editable={values.editable}
                />
              </ButtonsWrapper>
            ) : (
              <StyledModalActions>
                <CancelButton handleClose={() => handleClose(false, resetForm)} editView={editView} />
                <div>
                  <Button
                    size="large"
                    id="reset-button"
                    variant="outlined"
                    onClick={() => {
                      handleSubmit(values, resetForm);
                    }}
                    disabled={isSubmitting || isResetting}
                  >
                    Create and Add Another
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} isResetting={isResetting} editView={editView} editable />
                </div>
              </StyledModalActions>
            )}
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default observer(CreateEditFieldModal);
