import React from 'react';

import { Wrapper, SuccessMessage } from './styles';

const SuccessPage: React.FunctionComponent<{ message: string }> = ({
  message,
}: {
  message: string;
}): React.ReactElement => (
  <Wrapper>
    <SuccessMessage variant="h1">{message}</SuccessMessage>
  </Wrapper>
);

export default SuccessPage;
