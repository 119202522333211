import { DropAnimation, UniqueIdentifier, defaultDropAnimationSideEffects } from '@dnd-kit/core';

export type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

export interface SortableItemProps {
  id: UniqueIdentifier;
  index: number;
  name: string;
  disabled?: boolean;
  isLast?: boolean;
  required?: boolean;
}

export const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};
