import React from 'react';
import { RouteProps } from 'react-router';

import { reverse } from 'named-urls';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import SecureRoute from '../SecureRoute';

const PublicRoute: React.FunctionComponent<RouteProps> = (props): React.ReactElement => {
  const { session } = useStore();
  const { path, location } = props;

  // example: /publicSubmission/f98deb5b-2fe7-44ee-86a6-5bdcecc06b0f/
  // or
  // example: /publicForm/f98deb5b-2fe7-44ee-86a6-5bdcecc06b0f/
  // split by `/` returns ['', 'publicSubmission', 'f98deb5b-2fe7-44ee-86a6-5bdcecc06b0f']
  const uuid = location?.pathname.split('/')[2] || '';

  let redirectTo = routes.dashboard.home.toString();

  if (path === routes.publicSubmission.details.toString()) {
    redirectTo = reverse(routes.publicSubmission.details, { organisationId: uuid });
  } else if (path === routes.publicForm.details.toString()) {
    redirectTo = reverse(routes.publicForm.details, { formId: uuid });
  }

  const isAuthorized = redirectTo === routes.dashboard.home.toString() ? () => !session.isAuthenticated : () => true;

  return <SecureRoute isAuthorized={isAuthorized} redirectTo={redirectTo} {...props} />;
};

export default PublicRoute;
