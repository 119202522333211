import React from 'react';

import { MenuItem, SelectChangeEvent } from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';

import { observer } from 'mobx-react';

import { StyledSelect, StyledTextField } from 'components/Input/styles';

import { ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import ModalForChangingQuestionType from 'containers/EntitiesFormBuilder/components/ModalForChangingQuestionType';

import { FieldContainer, Row } from './styles';
import { getProtectorType, possibleProtectorTypesForQuestions } from './utils';
import { EditFieldProps } from './types';
import EditComponentForProtectorType from '../EditComponentForProtectorType/EditComponentForProtectorType';
import FieldFooter from './components/FieldFooter';

const EditField: React.FC<EditFieldProps> = ({
  onClickAway,
  description,
  questionType,
  lookup,
  properties,
  sectionId,
  fieldId,
  onChangeFieldProperties,
}): React.ReactElement => {
  const { formBuilder } = useStore();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [pendingQuestionType, setPendingQuestionType] = React.useState<ProtectorType | null>(null);

  const applyQuestionTypeChange = (newType: ProtectorType): void => {
    onChangeFieldProperties(newType, 'questionType');

    if (newType === ProtectorType.MultiChoice || newType === ProtectorType.SingleChoice) {
      onChangeFieldProperties(
        {
          enum: [
            {
              key: formBuilder.getNextContainerId(),
              value: 'Untitled 1',
            },
          ],
        },
        'properties'
      );
    }
  };

  const onChangeQuestionType = (e: SelectChangeEvent<unknown>): void => {
    const newType = e.target.value as ProtectorType;
    const field = formBuilder.formMapping.getFieldForQuestion(fieldId);

    if (field) {
      setPendingQuestionType(newType);
      setIsModalOpen(true);
      return;
    }

    applyQuestionTypeChange(newType);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    setPendingQuestionType(null);
  };

  const handleContinueModal = (): void => {
    if (pendingQuestionType) {
      applyQuestionTypeChange(pendingQuestionType);
      formBuilder.formMapping.updateFieldMapping(fieldId, null);
    }
    handleCloseModal();
  };

  return (
    <>
      <FieldContainer>
        <Row>
          <StyledTextField
            fullWidth
            size="small"
            style={{ backgroundColor: '#fff' }}
            defaultValue={description || ''}
            onChange={(e) => onChangeFieldProperties(e.target.value, 'description')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClickAway();
              }
            }}
            placeholder="Question name"
            sx={{
              '.MuiInputBase-input': {
                fontSize: '14px !important',
              },
            }}
          />
          <StyledSelect
            fullWidth
            placeholder="Choose an Option"
            value={questionType === ProtectorType.SingleChoice ? ProtectorType.MultiChoice : questionType}
            onChange={onChangeQuestionType}
            $withoutPadding
            style={{
              height: 'auto',
              backgroundColor: '#fff',
            }}
            sx={{
              '.MuiSelect-select': {
                gap: '8px',
                padding: '8px 14px !important',
              },
              '.MuiInputBase-input': {
                fontSize: '14px !important',
              },
            }}
          >
            {possibleProtectorTypesForQuestions.map((type) => {
              const { displayName, icon } = getProtectorType(type);
              return (
                <MenuItem
                  key={type}
                  value={type}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  {icon}
                  {displayName}
                </MenuItem>
              );
            })}
          </StyledSelect>
        </Row>
        <Row style={{ background: '#fff' }}>
          <EditComponentForProtectorType
            questionType={questionType as ProtectorType}
            lookupData={lookup}
            properties={properties}
            sectionId={sectionId}
            fieldId={fieldId}
            onSettingLookup={(val) => onChangeFieldProperties(val, 'lookupType')}
            onSettingProperties={(val) => onChangeFieldProperties(val, 'properties')}
          />
        </Row>

        {questionType === ProtectorType.Lookup && (
          <Row style={{ background: '#fff' }}>
            <InfoOutlined htmlColor="rgba(0, 0, 0, 0.54)" fontSize="small" />
            <p style={{ margin: 0, padding: 0, color: 'rgba(0, 0, 0, 0.60)' }}>
              Lookup fields allow users to relate this event with records from another module.
            </p>
          </Row>
        )}
        <FieldFooter
          questionType={questionType}
          sectionId={sectionId}
          fieldId={fieldId}
          onChangeFieldProperties={onChangeFieldProperties}
        />
      </FieldContainer>
      <ModalForChangingQuestionType isOpen={isModalOpen} onClose={handleCloseModal} onContinue={handleContinueModal} />
    </>
  );
};

export default observer(EditField);
