import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React, { useEffect } from 'react';
import PhoneInput from 'vatix-ui/lib/components/PhoneInput';
import { observer } from 'mobx-react';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';
import { useStore } from 'utils/hooks/store';

import { Container, Header, InputWrapper, InputLabel } from './styles';

type UserDetailsStepProps = {
  initialState: { name: string | null; email: string | null; phone: string | null };
  onRemember: (rememberUserDetails: boolean) => void;
  rememberUserDetails: boolean;
};

const UserDetailsStep: React.FC<UserDetailsStepProps> = ({ initialState, onRemember, rememberUserDetails }) => {
  const {
    entitiesFormSubmission: {
      saveUserDetails,
      publicSubmissionUserDetails,
      userDetailsRequiredNotFilled,
      publicSubmissionUserDetailsFields,
    },
  } = useStore();

  // Initialize store with values from localStorage when component mounts
  useEffect(() => {
    if (initialState.name) saveUserDetails('name', initialState.name);
    if (initialState.email) saveUserDetails('email', initialState.email);
    if (initialState.phone) saveUserDetails('phone', initialState.phone);
  }, [initialState]);

  const updateDetails = (key: 'name' | 'email' | 'phone', value: string): void => {
    saveUserDetails(key, value);
  };

  if (!publicSubmissionUserDetails) {
    return null;
  }

  return (
    <Container>
      <Header>Your details</Header>
      {publicSubmissionUserDetailsFields?.name && (
        <InputWrapper>
          <InputLabel>
            Full Name
            <span style={{ marginLeft: '4px', color: 'red' }}>*</span>
          </InputLabel>
          <CustomTextInputField
            description="Full Name"
            onChange={(value) => updateDetails('name', value as string)}
            value={publicSubmissionUserDetails?.name || initialState.name || ''}
            requiredNotFilled={userDetailsRequiredNotFilled.includes('name')}
          />
        </InputWrapper>
      )}
      {publicSubmissionUserDetailsFields?.email && (
        <InputWrapper>
          <InputLabel>
            Email address
            <span style={{ marginLeft: '4px', color: 'red' }}>*</span>
          </InputLabel>
          <CustomTextInputField
            description="Email address"
            onChange={(value) => updateDetails('email', value as string)}
            value={publicSubmissionUserDetails?.email || initialState.email || ''}
            requiredNotFilled={userDetailsRequiredNotFilled.includes('email')}
          />
        </InputWrapper>
      )}
      {publicSubmissionUserDetailsFields?.phone && (
        <InputWrapper>
          <InputLabel>
            Phone number
            <span style={{ marginLeft: '4px', color: 'red' }}>*</span>
          </InputLabel>
          <PhoneInput
            variant="outlined"
            defaultCountry="gb"
            placeholder="Phone number"
            error={userDetailsRequiredNotFilled.includes('phone')}
            onChange={(val) => updateDetails('phone', val as string)}
            value={publicSubmissionUserDetails?.phone || initialState.phone || ''}
          />
        </InputWrapper>
      )}
      <FormControl>
        <FormControlLabel
          value="remember"
          label="Remember my details"
          control={<Checkbox checked={rememberUserDetails} />}
          onChange={() => onRemember(!rememberUserDetails)}
        />
      </FormControl>
    </Container>
  );
};

export default observer(UserDetailsStep);
