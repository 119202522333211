import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';
import { Button } from 'vatix-ui/lib/components/Button';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { reverse } from 'named-urls';

import { useRouteMatch } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { observer } from 'mobx-react';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { CircularProgress } from '@mui/material';

import { FIELD_MANAGER_HELP_URL, EntityModules } from 'core/constants';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import { useStore } from 'utils/hooks/store';

import { LastPublished } from 'containers/LayoutEditor/styles';

import { StyledTab, StyledTabs } from 'containers/FormsDetails/components/FormDetailsContent/styles';

import { LoadingContainer } from 'containers/EntitiesFormSubmission/styles';
import FormToFieldMappingDetails from 'containers/FormToFieldMappingDetails';

import FormBuilderSettings from './components/FormBuilderSettings';

import FormBuilderDragAndDrop from './components/FormBuilderDragAndDrop';
import { HeaderAction, MainContainer } from './styles';

const EntitiesFormBuilder = (): React.ReactElement => {
  const { formBuilder, routing } = useStore();
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch(routes.dashboard.objectManager.details.formBuilder.details.toString());

  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  const { moduleName, formId } = match?.params as { moduleName: string; formId: string };

  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await formBuilder.dataManager.loadLayout(moduleName, formId);
    };
    fetchData();

    // Cleanup function that runs on unmount
    return () => formBuilder.resetForm();
  }, []);

  if (formBuilder.isLoaded === false) {
    return <CenteredCircularProgress aria-label="form-builder-loading" />;
  }

  const onPublish = async (): Promise<void> => {
    setSaving(true);
    await formBuilder.dataManager.saveForm();
    setSaving(false);
  };

  const onCancel = (): void => {
    formBuilder.resetForm();
    routing.push(reverse(routes.dashboard.objectManager.details.formBuilder.toString(), { moduleName }));
  };

  const redirectToHelpCenter = (): void => {
    // TODO: add link to help center - depends on the current open tab
    window.open(FIELD_MANAGER_HELP_URL, '_blank');
  };

  return (
    <Record
      disableMaxContentHeight
      navBarHeight={navBarHeight}
      Header={
        <MainHeader
          title="Form Builder"
          breadcrumbs={[
            {
              label: 'Object Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: getEntityTranslation[moduleName as EntityModules].plural,
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: 'Form Builder',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
          ]}
          action={
            <HeaderAction>
              {saving ? (
                <LoadingContainer>
                  <CircularProgress size="2rem" sx={{ color: '#9E9E9E' }} />
                  <p>Saving changes...</p>
                </LoadingContainer>
              ) : (
                formBuilder.lastPublished !== undefined && (
                  <LastPublished>{`Last published: ${formatDateTime(formBuilder.lastPublished)}`}</LastPublished>
                )
              )}

              <Button onClick={onCancel} variant="outlined" size="large">
                Cancel
              </Button>
              <Button
                onClick={onPublish}
                variant="contained"
                size="large"
                disabled={!formBuilder.contentWasEdited || saving}
              >
                Publish
              </Button>
            </HeaderAction>
          }
        />
      }
      Content={() => (
        <>
          <Button
            variant="text"
            size="large"
            onClick={redirectToHelpCenter}
            endIcon={<HelpOutlineOutlinedIcon />}
            style={{
              top: '50%',
              right: '0px',
              position: 'fixed',
              marginRight: '4px',
              background: 'rgba(7, 114, 229, 0.08)',
              transform: 'translateY(-50%)  rotate(-90deg)',
            }}
          >
            Help Centre
          </Button>

          <MainContainer>
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab label="Builder" id="form-builder-tab" value={0} />
              <StyledTab label="Field Mapping" id="field-mapping-tab" value={1} />
              <StyledTab label="Settings" id="form-setting-tab" value={2} />
            </StyledTabs>

            <TabPanel value={value} index={0}>
              <FormBuilderDragAndDrop />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <FormToFieldMappingDetails />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <FormBuilderSettings />
            </TabPanel>
          </MainContainer>
        </>
      )}
    />
  );
};

export default observer(EntitiesFormBuilder);
