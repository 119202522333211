import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import routes from 'core/routes';
import PublicSubmission from 'containers/Incidents/components/ReportIncident/components/PublicSubmission';
import PasswordReset from 'containers/PasswordReset';

import EntitiesFormPublicSubmission from 'containers/EntitiesFormPublicSubmission';

import Dashboard from '../../../Dashboard';
import Login from '../../../Login';
import { PrivateRoute, PublicRoute } from '../../../../components/Route';
import HomeRedirect from '../HomeRedirect';
import { isPrivatePath } from './utils';

const Routing: React.FunctionComponent = (): React.ReactElement => {
  const location = useLocation();
  const isPrivateRoute = isPrivatePath(routes.dashboard, location.pathname);

  return (
    <Switch>
      <HomeRedirect from="/" exact />
      <PublicRoute path={String(routes.publicSubmission.details)} component={PublicSubmission} />
      <PublicRoute path={String(routes.publicForm.details)} component={EntitiesFormPublicSubmission} />
      <PublicRoute path={routes.login} component={Login} exact />
      <PublicRoute path={String(routes.passwordReset)} component={PasswordReset} exact />
      {isPrivateRoute && <PrivateRoute path={location.pathname} component={Dashboard} />}
      <PublicRoute component={NotFound} />
    </Switch>
  );
};

export default Routing;
