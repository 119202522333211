import styled from 'styled-components';

export const SectionHeader = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-bottom: 20px;
  margin: 0;
  padding: 0;
`;

export const QuestionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const QuestionTitle = styled.h2<{ $required?: boolean }>`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &::after {
    content: ${({ $required }) => ($required ? "' *'" : "' '")};
    color: #d32f2f;
  }
`;
