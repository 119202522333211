import React from 'react';

import { Divider } from '@mui/material';

import Row from 'containers/IncidentDetails/components/IncidentInformation/components/Row';

import { Value } from 'containers/IncidentDetails/components/EditableTextInput/styles';

import { PublicSubmissionUserDetails } from 'stores/EntitiesFormSubmission/EntitiesFormSubmission';

import { SectionHeader, RowContainer } from '../styles';

const REPORTER_FIELDS = [
  { key: 'name', label: 'Full Name' },
  { key: 'email', label: 'Email address' },
  { key: 'phone', label: 'Phone number' },
] as const;

const ReporterDetailsSection: React.FC<{
  publicSubmissionDetails: PublicSubmissionUserDetails;
}> = ({ publicSubmissionDetails }) => (
  <React.Fragment key="reporter-details">
    <SectionHeader>
      <p>Reporter Details</p>
    </SectionHeader>
    {REPORTER_FIELDS.map((field, index) => {
      const value = publicSubmissionDetails[field.key];
      if (!value) return null;

      return (
        <React.Fragment key={field.key}>
          <RowContainer>
            <Row name={field.label}>
              <Value>{value}</Value>
            </Row>
          </RowContainer>
          {index < REPORTER_FIELDS.length - 1 && value && <Divider />}
        </React.Fragment>
      );
    })}
  </React.Fragment>
);

export default ReporterDetailsSection;
