import React from 'react';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { LocationAnswerType, ProtectorType } from 'utils/api/types';

import EntitySearchField from 'components/EntitySearchField';

import CustomTimePicker from 'containers/IncidentDetails/components/CustomTimePicker';
import CustomDateTimePicker from 'containers/IncidentDetails/components/CustomDateTimePicker';
import CustomNumberInputField from 'containers/IncidentDetails/components/CustomNumberInputField';
import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';
import CustomUserDropdownField from 'containers/IncidentDetails/components/CustomUserDropdownField';
import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';
import CustomLocationTextInput from 'containers/IncidentDetails/components/CustomLocationTextInput';

import EntitiesDropDownField from '../EntitiesDropDownField';
import { EntitiesFormFieldProps } from './types';

// TODO: This component adapts the incident form field implementation for entities.
// Consider refactoring to reduce duplication and improve maintainability.

export const EntitiesFormField = (props: EntitiesFormFieldProps): React.ReactElement => {
  const { type, value, onChange, description, items, requiredNotFilled, lookupType } = props;
  if (type === undefined) {
    return <p>Type not supported</p>;
  }

  const commonTextInputProps = {
    value,
    description,
    onChange,
    requiredNotFilled,
  };

  const commonDropDownProps = {
    type,
    value: value as string[],
    items,
    description,
    onChange,
    requiredNotFilled,
  };

  switch (type) {
    case ProtectorType.ShortText:
      return <CustomTextInputField {...commonTextInputProps} />;
    case ProtectorType.LongText:
      return <CustomTextInputField {...commonTextInputProps} multiline />;
    case ProtectorType.SingleChoice: {
      return <EntitiesDropDownField {...commonDropDownProps} />;
    }
    case ProtectorType.MultiChoice: {
      return <EntitiesDropDownField {...commonDropDownProps} />;
    }
    case ProtectorType.Number:
      return <CustomNumberInputField {...props} items={undefined} />;
    case ProtectorType.Date:
      return <CustomDatePicker {...props} items={undefined} />;
    case ProtectorType.Time:
      return <CustomTimePicker {...props} items={undefined} />;
    case ProtectorType.DateTime:
      return <CustomDateTimePicker {...props} items={undefined} />;
    case ProtectorType.User:
      return <CustomUserDropdownField {...props} items={undefined} />;
    case ProtectorType.Location:
      return (
        <CustomLocationTextInput
          onChange={onChange}
          location={value ? (value as LocationAnswerType).address : undefined}
          requiredNotFilled={requiredNotFilled}
        />
      );
    case ProtectorType.Lookup:
      return (
        <EntitySearchField
          field="lookup"
          updateField={(field: string, val: UuidableName | null) => {
            onChange(val ? val.uuid : null);
          }}
          data={value ? (value as UuidableName) : null}
          module={lookupType}
        />
      );
    default:
      return <p>Type not supported</p>;
  }
};

export default EntitiesFormField;
