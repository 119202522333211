import React from 'react';

import { observer } from 'mobx-react';

import ConfirmCheck from 'components/Icons/ConfirmCheck';

import { SimpleModal, StyledModalTitle } from './styles';

const SubmittedModal: React.FunctionComponent<{ openModal: boolean }> = ({ openModal }): React.ReactElement => (
  <SimpleModal
    fullWidth
    maxWidth="md"
    open={openModal}
    aria-labelledby="submitted-modal"
    PaperProps={{
      square: true,
    }}
  >
    <ConfirmCheck data-testid="confirm-check-icon" />
    <StyledModalTitle>Event has been successfully submitted!</StyledModalTitle>
  </SimpleModal>
);

export default observer(SubmittedModal);
