import React from 'react';

import { FormControlLabel } from '@mui/material';

import Logger from 'vatix-ui/lib/utils/logger/Logger';

import Switch from '@mui/material/Switch';

import { ColumnActions } from 'components/Grid/types';

import { useStore } from 'utils/hooks/store';

import API from 'utils/api';

export type StatusCellProps = {
  row: { uuid: string; settings: { isActive: boolean } };
  actions: ColumnActions;
};

const StatusCell: React.FC<StatusCellProps> = ({ row, actions }) => {
  const { notification } = useStore();
  const {
    settings: { isActive },
    uuid,
  } = row;
  const onClick = (event: React.SyntheticEvent): void => {
    onToggleActive();
    event.stopPropagation();
  };

  const onToggleActive = React.useCallback(async (): Promise<void> => {
    try {
      await API.updateEntityForm('events', uuid, {
        // @ts-ignore
        settings: {
          isActive: !isActive,
        },
      })();
      actions.refreshData();
      notification.enqueueSuccessSnackbar('Form has been updated');
    } catch (e) {
      notification.enqueueErrorSnackbar('Form cannot be updated right now.');
      Logger.error(`Invalid update form API response. Form ID: ${uuid}`, e);
    }
  }, [isActive, uuid]);

  return (
    <FormControlLabel
      sx={{
        gap: '12px',
        '& .MuiFormControlLabel-label': {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: '14px',
        },
      }}
      control={<Switch size="small" onClick={onClick} checked={isActive} aria-label="Active switch" />}
      label="Active"
    />
  );
};

export default StatusCell;
