import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
`;

export const Container = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  @media (max-width: 768px) {
    margin: 0 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Overview = styled.div`
  display: flex;
  min-height: 90%;
  margin-bottom: 20px;
  flex-direction: column;
  background-color: #f5f5f5;
`;

export const SubmissionContainer = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: 1200px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  gap: 20px;
  align-self: stretch;
`;

export const SectionTitle = styled.p`
  font-size: 24px;
  line-height: 160%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 49px;
  background-color: #f5f5f5;
  padding-top: 20px;
`;

export const HeaderTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding: 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 49px;
  background-color: white;
  padding 20px;
`;
