import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Divider } from '@mui/material';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import EventDetailsStore from 'stores/EventDetails';

import Row from 'containers/IncidentDetails/components/IncidentInformation/components/Row';

import { AnswerTypes, ProtectorType } from 'utils/api/types';

import CustomFieldDisplayValue from 'containers/IncidentDetails/components/CustomFieldDisplayValue';
import { EntityModules } from 'core/constants';

import { getContentForKey } from 'stores/EventDetails/helpers';

import { EntitiesStores } from '../types';
import OriginalSubmissionHeader from './OriginalSubmissionHeader';

import { SectionContainer, SectionHeader, RowContainer } from './styles';
import ReporterComments from './ReporterComments';
import ReporterDetailsSection from './ReporterDetailsSection';

const EntitySubmissionTab: React.FunctionComponent<{ store: EntitiesStores }> = ({ store }): React.ReactElement => {
  const { details, prefix } = store;
  const detailsType = details as EventDetailsStore['details'];

  if (!details || !detailsType) {
    return <DataError />;
  }

  const eventReporter = useMemo(() => detailsType.entity[`${prefix}eventReporter`], [detailsType.entity, prefix]);
  const eventId = useMemo(() => detailsType.entity[`${prefix}eventID`], [detailsType.entity, prefix]);
  const { stack } = detailsType.submission;

  const renderSection = useCallback(
    (sectionKey: string) => {
      const { title, order, properties } = detailsType.submission.form.form.properties[sectionKey];
      return (
        <React.Fragment key={sectionKey}>
          <SectionHeader>
            <p>{title}</p>
          </SectionHeader>
          {order.map((property, index) => {
            const {
              description,
              questionType,
              properties: { lookupType },
            } = properties[property];

            const value = getContentForKey({
              form: detailsType.submission.form.form,
              content: detailsType.submission.content,
              key: property,
              sectionKey,
            });

            return (
              <>
                <RowContainer key={property}>
                  <Row name={description || '-'}>
                    <CustomFieldDisplayValue
                      schema={undefined}
                      type={questionType as ProtectorType}
                      value={value as AnswerTypes}
                      lookupType={(lookupType as unknown) as EntityModules}
                    />
                  </Row>
                </RowContainer>
                {index !== order.length - 1 && <Divider />}
              </>
            );
          })}
        </React.Fragment>
      );
    },
    [detailsType]
  );

  return (
    <>
      <SectionContainer>
        <OriginalSubmissionHeader
          id={eventId.value as number}
          created={detailsType.createdAt}
          reporter={eventReporter.value}
        />
        {/* if it is a public submission and reporter details are not empty display section with reporter data */}
        {detailsType.submission.publicSubmissionDetails &&
          Object.keys(detailsType.submission.publicSubmissionDetails).length > 0 && (
            <ReporterDetailsSection publicSubmissionDetails={detailsType.submission.publicSubmissionDetails} />
          )}

        {stack.map((sectionKey) => renderSection(sectionKey))}
      </SectionContainer>
      <ReporterComments />
    </>
  );
};

export default observer(EntitySubmissionTab);
