/* eslint-disable max-len */
import * as React from 'react';

function DateAndTimeIcon(): React.ReactElement {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21484_299906)">
        <path
          d="M14 6.66634H2M14 8.33301V5.86634C14 4.74624 14 4.18618 13.782 3.75836C13.5903 3.38204 13.2843 3.07607 12.908 2.88433C12.4802 2.66634 11.9201 2.66634 10.8 2.66634H5.2C4.0799 2.66634 3.51984 2.66634 3.09202 2.88433C2.71569 3.07607 2.40973 3.38204 2.21799 3.75836C2 4.18618 2 4.74624 2 5.86634V11.4663C2 12.5864 2 13.1465 2.21799 13.5743C2.40973 13.9506 2.71569 14.2566 3.09202 14.4484C3.51984 14.6663 4.0799 14.6663 5.2 14.6663H8M10.6667 1.33301V3.99967M5.33333 1.33301V3.99967"
          stroke="black"
          strokeOpacity="0.54"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_21484_299906)">
          <path
            d="M12.3259 9.61133C10.6393 9.61133 9.27344 10.9802 9.27344 12.6669C9.27344 14.3536 10.6393 15.7224 12.3259 15.7224C14.0157 15.7224 15.3845 14.3536 15.3845 12.6669C15.3845 10.9802 14.0157 9.61133 12.3259 9.61133ZM12.329 15.1113C10.9784 15.1113 9.88455 14.0174 9.88455 12.6669C9.88455 11.3163 10.9784 10.2224 12.329 10.2224C13.6795 10.2224 14.7734 11.3163 14.7734 12.6669C14.7734 14.0174 13.6795 15.1113 12.329 15.1113Z"
            fill="black"
            fillOpacity="0.54"
          />
          <path
            d="M12.3259 9.51133C10.5839 9.51133 9.17344 10.9251 9.17344 12.6669C9.17344 14.4086 10.5839 15.8224 12.3259 15.8224C14.0708 15.8224 15.4845 14.4088 15.4845 12.6669C15.4845 10.9249 14.0708 9.51133 12.3259 9.51133ZM12.329 15.0113C11.0337 15.0113 9.98455 13.9622 9.98455 12.6669C9.98455 11.3716 11.0337 10.3224 12.329 10.3224C13.6243 10.3224 14.6734 11.3716 14.6734 12.6669C14.6734 13.9622 13.6243 15.0113 12.329 15.0113Z"
            stroke="black"
            strokeOpacity="0.54"
            strokeWidth="0.2"
          />
          <path
            d="M12.4818 11.1387H12.0234V12.972L13.6276 13.9345L13.8568 13.5587L12.4818 12.7428V11.1387Z"
            fill="black"
            fillOpacity="0.54"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_21484_299906">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_21484_299906">
          <rect width="7.33333" height="7.33333" fill="white" transform="translate(8.66406 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DateAndTimeIcon;
