import styled, { css, keyframes } from 'styled-components';

export const DraggableItemStyled = styled.div<{ $isDragging: boolean; $disabled: boolean }>`
  border: ${({ $disabled }) => ($disabled ? '1px solid #F6F8FA' : '1px solid #e6e6e6')};
  background-color: ${({ $disabled }) => ($disabled ? '#F6F8FA' : '#fff')};
  color: ${({ $disabled }) => ($disabled ? '#CDD0D5' : '#525866')};
  border-radius: 6px;
  margin: 8px 0;
  opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};
  padding: 4px 6px;
  p {
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: #525866;
    text-overflow: ellipsis;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
`;

const pop = keyframes`
  0% {
    transform: scale(1);
    box-shadow:  0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(34, 33, 81, 0.15)
  }
  100% {
    transform: scale(1.1);
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(34, 33, 81, 0.15);
  }
`;

export const ItemStyled = styled.div<{ dragging: boolean; disabled: boolean; dragOverlay: boolean; $isLast: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 6px;
  margin: 6px;
  margin-bottom: ${({ $isLast }) => ($isLast ? '6px' : '4px')};
  background-color: #fff;
  border: 1px solid #E2E4E6;
  border-radius: 8px;
  box-sizing: border-box;
  color: #333;

  p{
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  ${({ dragging }) =>
    dragging &&
    css`
      z-index: 0;
      border-radius: 8px;
      border: 1px solid #0772e5;
      background: #f1f6fc;
      opacity: 0.5;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: #999;
      background-color: #f1f1f1;
      cursor: not-allowed;
    `}

  ${({ dragOverlay }) =>
    dragOverlay &&
    css`
      cursor: inherit;
      animation: ${pop} 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
      opacity: 1;
      border: 1px solid #0772e5;
      max-width: 250px;
      margin-right: 10px;
      p {
        padding: 0;
        overflow: hidden;
        margin: 0 5px 0 5px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}
`;

export const Actions = styled.span`
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
`;

const fadeInAnimation = keyframes`
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 `;

export const Wrapper = styled.li<{ fadeIn: boolean; dragOverlay: boolean }>`
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1))
    scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;

  ${({ fadeIn }) =>
    fadeIn &&
    css`
      animation: ${fadeInAnimation} 500ms ease;
    `}

  ${({ dragOverlay }) =>
    dragOverlay &&
    css`
      --scale: 1.05;
      --box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
      --box-shadow-picked-up: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
      z-index: 999;
    `}
`;
