import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { Switch, FormControlLabel } from '@mui/material';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
} from '@dnd-kit/core';

import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Button } from 'vatix-ui/lib/components/Button';
import AddIcon from '@mui/icons-material/Add';

import { DropdownOptionsProps, SortableItemType } from './types';
import { OptionContainer, OptionHeader } from './styles';
import DropdownSortableItem from './components/DropDownSortableItem';
import InactiveItems from './components/InactiveItems';

const DropdownOptions: React.FunctionComponent<DropdownOptionsProps> = ({
  editView,
  options,
  setOptions,
  multipleAnswers,
  setMultipleAnswers,
}): React.ReactElement => {
  const optionsContainerRef = React.useRef<HTMLDivElement>(null);

  const activeOptions = options.filter((item) => item.active);
  const inactiveOptions = options.filter((item) => !item.active);

  const [displayInactiveOptions, setDisplayInactiveOptions] = useState(false);
  const handleChangeDisplay = (): void => {
    setDisplayInactiveOptions((prev) => !prev);
  };

  const handleChangeToInactive = (key: string): void => {
    const newData = options.map((item) => {
      if (item.key === key) {
        return { ...item, active: true };
      }
      return item;
    });
    setOptions(newData);

    if (newData.filter((item) => !item.active).length === 0) {
      setDisplayInactiveOptions(false);
    }
  };

  const [activeId, setActiveId] = useState<string | null>(null);

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = options.findIndex((item) => item.key === active.id);
      const newIndex = options.findIndex((item) => item.key === over.id);
      const newData = arrayMove(options, oldIndex, newIndex);

      setOptions(newData);
    }
  };

  const handleAddNewOption = (): void => {
    const newOption: SortableItemType = {
      key: `untitled${options.length + 1}`,
      value: `Untitled ${options.length + 1}`,
      displayKey: `untitled${options.length + 1}`,
      active: true,
      closed: false,
    };

    setOptions([...options, newOption]);

    // Scroll to bottom after adding new option
    setTimeout(() => {
      optionsContainerRef.current?.scrollTo({
        top: optionsContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor)
  );

  const handleDragStart = (event: DragStartEvent): void => {
    setActiveId(event.active.id as string);
  };

  return (
    <>
      <OptionContainer>
        <OptionHeader>
          <p>Options</p>
          {editView && inactiveOptions.length > 0 && (
            <Button variant="text" color="primary" size="medium" onClick={handleChangeDisplay}>
              {displayInactiveOptions ? 'Show active options' : 'Show inactive options'}
            </Button>
          )}
        </OptionHeader>
        {displayInactiveOptions ? (
          <InactiveItems inactiveOptions={inactiveOptions} handleActiveChange={handleChangeToInactive} />
        ) : (
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <SortableContext items={options.map((item) => item.key)} strategy={verticalListSortingStrategy}>
                <div
                  ref={optionsContainerRef}
                  style={{
                    maxHeight: '220px',
                    overflow: 'auto',
                    position: 'relative',
                  }}
                >
                  {activeOptions.length > 0 ? (
                    activeOptions.map((item) => (
                      <DropdownSortableItem
                        id={item.key}
                        key={item.key}
                        editView={editView}
                        item={item}
                        options={options}
                        setOptions={setOptions}
                      />
                    ))
                  ) : (
                    <p>No options added yet. Click &apos;Add option&apos; to create the first dropdown item.</p>
                  )}
                </div>
                <DragOverlay style={{ transform: 'unset' }}>
                  {activeId ? (
                    <div
                      style={{
                        opacity: 0,
                      }}
                    >
                      <DropdownSortableItem
                        id={activeId}
                        editView={editView}
                        item={options.find((item) => item.key === activeId)!}
                        options={options}
                        setOptions={setOptions}
                      />
                    </div>
                  ) : null}
                </DragOverlay>
              </SortableContext>
            </DndContext>
          </div>
        )}
        {!displayInactiveOptions && (
          <Button startIcon={<AddIcon />} value="text" onClick={handleAddNewOption} color="primary" size="small">
            Add option
          </Button>
        )}
      </OptionContainer>
      <FormControlLabel
        control={
          <Switch
            size="small"
            id="multipleAnswers"
            checked={multipleAnswers}
            onChange={(e) => setMultipleAnswers(e.target.checked)}
          />
        }
        disabled={editView}
        label="Allow Multiple Answers"
        style={{ margin: 0 }}
      />
    </>
  );
};

export default observer(DropdownOptions);
