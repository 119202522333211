import React from 'react';

import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';
import { escapeRegExp } from 'lodash';
import { GridFilterOperator } from '@mui/x-data-grid';

import styled from 'styled-components';

import { MenuItem } from '@mui/material';

import { PickerProps } from 'components/Grid/operators/types';

import { StyledSelect } from 'components/Input/styles';

export const StyledMenuItem = styled(MenuItem)`
  font-size: 1.3rem;
  line-height: 2rem;
  p {
    margin: 0;
  }
`;

const StatusFilteringOperator: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <StyledSelect
    {...props}
    value={item.value}
    fullWidth
    onChange={(newValue) => applyValue({ ...item, value: newValue.target.value })}
  >
    <StyledMenuItem key="true" value="true">
      <p>Active</p>
    </StyledMenuItem>
    <StyledMenuItem key="false" value="false">
      <p>Inactive</p>
    </StyledMenuItem>
  </StyledSelect>
);

export default ([
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) {
        return null;
      }
      const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
      return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
    },
    InputComponent: StatusFilteringOperator,
    InputComponentProps: { value: null },
    getValueAsString: (value: string) => value,
  },
] as unknown) as GridFilterOperator[];
