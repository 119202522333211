import { Button } from 'vatix-ui/lib/components/Button';

import CustomModal from 'components/CustomModal';

interface CancelFlowModalProps {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
}

const CancelFlowModal: React.FC<CancelFlowModalProps> = ({ open, onAction, onClose }): React.ReactElement => (
  <CustomModal
    openModal={open}
    onClose={onClose}
    modalTitle="Cancel Submission"
    modalDescription="Your progress will be lost if you exit now. Are you sure you want to cancel the submission?"
    cancelButton={
      <Button onClick={onClose} variant="outlined">
        No
      </Button>
    }
    submitButton={
      <Button onClick={onAction} variant="contained">
        Yes
      </Button>
    }
  />
);

export default CancelFlowModal;
