import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { ClickAwayListener } from '@mui/material';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { EnumOptions, ProtectorType } from 'utils/api/types';

import { QuestionContainer } from './styles';
import DisplayField from './components/DisplayField';
import EditField from './components/EditField';

const SortableItem: React.FC<{ sectionId: UniqueIdentifier; fieldId: UniqueIdentifier }> = ({
  sectionId,
  fieldId,
}): React.ReactElement => {
  const { formBuilder } = useStore();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: fieldId });
  const style = {
    border: isDragging ? '1px solid rgb(7, 114, 229)' : undefined,
    opacity: isDragging ? 0.5 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const data = formBuilder.data?.properties[sectionId].properties[fieldId];

  const [fieldData, setFieldData] = React.useState({
    description: data ? data.description : '',
    questionType: data?.questionType,
    lookupType: (data?.properties?.lookupType as unknown) as string,
    properties: data?.properties,
  });

  const onChangeFieldProperties = (
    val: ProtectorType | string | { enum?: EnumOptions },
    prop: keyof typeof fieldData
  ): void => {
    setFieldData((prev) => ({ ...prev, [prop]: val }));
  };

  // check if the field is being edited
  const [editing, setEditing] = React.useState(
    formBuilder.currentEditedQuestion?.fieldId === fieldId && formBuilder.currentEditedQuestion?.sectionId === sectionId
  );

  const onEdit = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setEditing(true);
  };

  const onClickAway = (): void => {
    // block main click away event if dropdown option is in edit mode
    if (formBuilder.isDropdownOptionInEdit) return;

    // block click away event if the field is not being edited
    if (editing === false) return;

    setEditing(false);
    formBuilder.questionLogic.updatedQuestionValues(
      fieldId as string,
      fieldData.questionType,
      fieldData.description,
      fieldData.lookupType,
      fieldData.properties
    );
    formBuilder.setCurrentEditedQuestion(undefined);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway} key={fieldId} mouseEvent="onMouseDown" touchEvent="onTouchStart">
      <div onClick={onEdit} ref={setNodeRef} tabIndex={0} role="button" key={`${fieldId}-${editing}`}>
        {editing ? (
          <EditField
            onClickAway={onClickAway}
            description={fieldData.description}
            questionType={fieldData.questionType as ProtectorType}
            lookup={fieldData.lookupType}
            properties={fieldData.properties}
            sectionId={sectionId as string}
            fieldId={fieldId as string}
            onChangeFieldProperties={onChangeFieldProperties}
          />
        ) : (
          <QuestionContainer style={style}>
            <div className="drag-indicator" {...attributes} {...listeners}>
              <DragIndicatorIcon style={{ color: '#D9D9D9' }} />
            </div>
            <DisplayField
              description={fieldData.description || ''}
              questionType={fieldData.questionType}
              isRequired={formBuilder.questionLogic.isQuestionRequired(sectionId as string, fieldId as string)}
              // if the filed is protector type lookup, we need to pass the lookup data
              lookupData={fieldData.lookupType}
              // if the field is protector type single choice or multi choice, we need to pass the properties
              properties={fieldData.properties}
            />
          </QuestionContainer>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default observer(SortableItem);
