import React from 'react';

import styled from 'styled-components';

import { InspectionStatusSelectProps } from './types';
import { StyledMenuItem, StyledSelect } from '../styles';

const Text = styled.p`
  color: #000;
  margin: 0;
  padding: 0;
`;

const InspectionStatusSelect: React.FunctionComponent<InspectionStatusSelectProps> = ({
  onChange,
  value,
  ...props
}) => (
  <StyledSelect {...props} fullWidth onChange={(event) => onChange(event.target.value as string)} value={value}>
    <StyledMenuItem key="not_empty" value="not_empty">
      <Text>Submitted</Text>
    </StyledMenuItem>
    <StyledMenuItem key="empty" value="empty">
      <Text>In progress</Text>
    </StyledMenuItem>
  </StyledSelect>
);

export default InspectionStatusSelect;
