import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import { useRouteMatch } from 'react-router-dom';

import WrappedDataError from 'vatix-ui/lib/components/Error/WrappedDataError';

import { ActivityType } from 'vatix-ui/lib/utils/api/types';

import { useStore } from 'utils/hooks/store';

import {
  IncidentOverview,
  ButtonWrapper,
  HeaderWrapper,
  HeaderTitle,
  Container,
  LogoContainer,
  SubmissionContainer,
} from './styles';

import SuccessPage from '../../../../../../components/SuccessPage/SuccessPage';
import ReportingIncidentCommon from '../ReportIncidentCommon';
import SubmissionActivity from '../SubmissionActivity';
import SubmittedIncidentModal from '../SubmittedIncidentModal';

import logo from '../../../../../../assets/images/VatixLogoPublicSubmission.png';

const PublicSubmission: React.FC = (): React.ReactElement => {
  const {
    incidentDetails: {
      customIncidentType,
      loadPublicIncidentType,
      createPublicIncident,
      updateIncidentField,
      incidentContent,
      showConfirmPopup,
      submissionActivities,
      hasError,
      missingRequiredValues,
    },
  } = useStore();
  const match = useRouteMatch();
  const finished = submissionActivities?.finishedUploading;

  const { organisationId } = match.params as { organisationId: string };

  useEffect(() => {
    loadPublicIncidentType(organisationId);
  }, []);

  const onUpdate = async (): Promise<void> => {
    if (incidentContent && customIncidentType) {
      await createPublicIncident(incidentContent, customIncidentType.schema, organisationId);
    }
  };

  if (finished) {
    return <SuccessPage message="Incident reported successfully!" />;
  }

  if (hasError) {
    return <WrappedDataError />;
  }

  return (
    <>
      <LogoContainer>
        <img alt="Vatix logo" src={logo} />
      </LogoContainer>
      <HeaderWrapper>
        <HeaderTitle>New Submission</HeaderTitle>
      </HeaderWrapper>
      <IncidentOverview>
        <SubmissionContainer>
          <ReportingIncidentCommon
            customIncidentType={customIncidentType}
            incidentContent={incidentContent}
            updateIncidentField={updateIncidentField}
          />
          <Container>
            <ButtonWrapper>
              <Button
                fullWidth={window.innerWidth <= 768}
                disabled={
                  missingRequiredValues.length > 0 ||
                  !incidentContent ||
                  Object.values(incidentContent)
                    .map((v) => v && String(v).replaceAll('\n', ''))
                    .filter(Boolean).length === 0
                }
                onClick={onUpdate}
                id="submit_button"
                variant="contained"
                size="large"
              >
                Submit
              </Button>
            </ButtonWrapper>
            <SubmissionActivity />
          </Container>
        </SubmissionContainer>
      </IncidentOverview>
      <SubmittedIncidentModal
        open={showConfirmPopup}
        flowWithFile={submissionActivities?.activities?.filter((i) => i.type !== ActivityType.Message).length !== 0}
      />
    </>
  );
};

export default observer(PublicSubmission);
