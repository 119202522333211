import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover, MenuItem as MuiMenuItem } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { reverse } from 'named-urls';

import styled from 'styled-components';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { EntityModules } from 'core/constants';

export const MenuItem = styled(MuiMenuItem)`
  font-size: 14px;
`;

const ActionCell: React.FunctionComponent<{ rowData: { uuid: string } }> = ({ rowData }) => {
  const { routing } = useStore();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    const path = reverse(routes.dashboard.objectManager.details.formBuilder.toString(), {
      moduleName: EntityModules.Events,
    });
    routing.push(`${path + rowData.uuid}/`);
  };

  return (
    <>
      <GridActionsCellItem icon={<MoreVert />} label="" color="inherit" onClick={handleOpen} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          id="edit-button"
          onClick={async (e) => {
            handleEditClick(e);
          }}
        >
          Edit
        </MenuItem>
      </Popover>
    </>
  );
};

export default observer(ActionCell);
