import React from 'react';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import ClearIcon from '@mui/icons-material/Clear';

import { EnumOptions, ProtectorType } from 'utils/api/types';

import { StyledAutocomplete } from 'components/Input/styles';

import Chip from 'components/Chip';

type EntitiesDropDownFieldProps = {
  type: ProtectorType;
  value: string[];
  items: EnumOptions;
  description: string;
  onChange: (value: string[]) => void;
  requiredNotFilled?: boolean;
};

export const EntitiesDropDownField = ({
  type,
  value,
  items,
  onChange,
  description,
  requiredNotFilled,
}: EntitiesDropDownFieldProps): React.ReactElement => {
  const [currentValue, setCurrentValue] = React.useState(value);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [firstClick, setFirstClick] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const toggleInputMode = (): void => {
    if (inputRef.current) {
      setOpen(true);
      inputRef.current.setAttribute('inputmode', firstClick ? 'none' : 'text');
      inputRef.current.setAttribute('aria-expanded', 'true');
      setFirstClick(false);
    }
  };

  const handleArrowClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setOpen(!open);
    if (open && inputRef.current) {
      inputRef.current.setAttribute('inputmode', 'none');
    }
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onChange([]);
    setCurrentValue([]);
  };

  const getAutocompleteValue = (val: string[]): { key: string; value: string }[] | undefined => {
    if (!val || val.length === 0) {
      return [];
    }
    if (type === ProtectorType.MultiChoice) {
      return val
        .map((key) => items.find((item) => item.key === key))
        .filter((item): item is { key: string; value: string } => item !== undefined);
    }

    const singleItem = items.find((item) => item.key === val[0]);

    // @ts-ignore
    return singleItem || [];
  };

  return (
    <FormControl fullWidth>
      <StyledAutocomplete
        freeSolo
        fullWidth
        open={open}
        size="medium"
        disablePortal
        role="textbox"
        options={items}
        data-testid={description}
        key={`${description}-${type}`}
        onClose={() => setOpen(false)}
        value={getAutocompleteValue(currentValue)}
        multiple={type === ProtectorType.MultiChoice}
        disableCloseOnSelect={type === ProtectorType.MultiChoice}
        isOptionEqualToValue={(option, val) => option?.key === val?.key}
        id={`${type === ProtectorType.MultiChoice ? 'multi' : 'single'}-drop-down-field`}
        renderInput={(params) => (
          <TextField
            {...params}
            error={requiredNotFilled}
            inputRef={inputRef}
            placeholder={type === ProtectorType.MultiChoice ? 'Choose option(s)' : 'Choose an option'}
            onClick={toggleInputMode}
            InputProps={{
              ...params.InputProps,
              sx: { paddingRight: '9px !important' },
              endAdornment: (
                <InputAdornment position="end" sx={{ height: 'auto' }}>
                  {currentValue && (
                    <IconButton onClick={handleClear} aria-label="Clear" title="Clear" sx={{ padding: '4px' }}>
                      <ClearIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  )}

                  <IconButton onClick={handleArrowClick} aria-label="toggle dropdown" sx={{ padding: '4px' }}>
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        style={{
          height: 'fit-content',
          width: '100%',
        }}
        sx={{
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
            fontSize: '16px',
          },
          '& + .MuiChip-root': {
            fontSize: '16px',
          },
        }}
        onChange={(_, newValue) => {
          if (newValue) {
            if (Array.isArray(newValue)) {
              onChange((newValue as { key: string }[]).map((item) => item.key));
              setCurrentValue((newValue as { key: string }[]).map((item) => item.key));
            } else {
              onChange([(newValue as { key: string }).key]);
              setCurrentValue([(newValue as { key: string }).key]);
            }
          } else {
            onChange([]);
            setCurrentValue([]);
          }
        }}
        onInputChange={(_, __, reason) => {
          if (reason === 'clear') {
            onChange([]);
            setCurrentValue([]);
          }
        }}
        getOptionLabel={(option) => {
          if (Array.isArray(option)) {
            return option.length > 0 ? option[0].value : '';
          }
          return (option as { value: string }).value || '';
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} label={option?.value || ''} fontSize={14} variant="filled" />
          ))
        }
        disableClearable={currentValue && currentValue.length === 0}
      />
    </FormControl>
  );
};

export default EntitiesDropDownField;
