import { Button } from 'vatix-ui/lib/components/Button';

import { styled } from 'styled-components';

import CustomModal from 'components/CustomModal';

const Description = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-bottom: 0px;
`;

const RedirectModal: React.FC<{
  openModal: boolean;
}> = ({ openModal }): React.ReactElement => (
  <CustomModal
    openModal={openModal}
    submitButton={
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh now
      </Button>
    }
    modalTitle="The Form Layout Has Been Updated"
    modalWidth="sm"
  >
    <Description>
      Your progress has been saved, but you must refresh the page to continue with the latest version. After refreshing,
      you will return to the beginning of the form.
    </Description>
  </CustomModal>
);

export default RedirectModal;
