import styled from 'styled-components';

export const HeaderAction = styled.div`
  display: flex;
  flex:
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
`;

export const SubHeading = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const PreviewSection = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
  margin-top: 20px;
`;

export const PreviewSectionTitle = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin: 0;
  padding: 0;
`;

export const PreviewSectionDescription = styled.h2`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-top: 5px;
`;

export const PreviewSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 20px;
`;

export const PreviewSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const PreviewSectionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 10px;
  padding-bottom: 10px;

  h1 {
    flex: 1;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;
