import React from 'react';
import { observer } from 'mobx-react';
import { GridValidRowModel } from '@mui/x-data-grid';
import { VatixError } from 'vatix-ui/lib/components/Error/types';

import Grid from 'components/Grid/Grid';
import { ColumnDefinition } from 'components/Grid/types';
import Chip from 'components/Chip';

import { AuditsIcon } from 'containers/Dashboard/components/SideBar/Icons/AuditsIcon';
import { ProtectorIcon } from 'containers/Dashboard/components/SideBar/Icons/ProtectorIcon';
import { IncidentIcon } from 'containers/Dashboard/components/SideBar/Icons/IncidentsIcon';

import { useStore } from 'utils/hooks/store';

import { EntityModules } from 'core/constants';

import LicensesAssignedCell from '../LicensesAssignedCell';

const LicenseNameMapping: Record<string, string> = Object.freeze({
  workflows: 'Audits',
  protectorAlarms: 'Lone Working',
  protectorIncidents: 'Incidents',
  events: 'Events',
});

const LicenseIconMapping: Record<string, React.ReactElement> = Object.freeze({
  workflows: <AuditsIcon fill="rgb(7, 114, 229)" />,
  protectorAlarms: <ProtectorIcon fill="rgb(7, 114, 229)" />,
  protectorIncidents: <IncidentIcon fill="rgb(7, 114, 229)" />,
  events: <IncidentIcon fill="rgb(7, 114, 229)" />,
});

const basicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ value }: { value: string }) => (
      <Chip
        size="small"
        variant="filled"
        colors={{ background: 'transparent', iconLeftBackground: 'rgba(7, 114, 229, 0.05)' }}
        label={LicenseNameMapping[value]}
        iconLeft={LicenseIconMapping[value]}
        iconLeftDisableInnerMargin
        iconLeftGapWidth={12}
      />
    ),
  },
  {
    field: 'maxSeats',
    headerName: 'Number of licences',
  },
  {
    field: 'overview',
    headerName: 'Licences assigned',
    renderCell: ({ value }: { value: { taken: number; max: number } }) => (
      <LicensesAssignedCell licensesAssigned={value} />
    ),
  },
] as unknown) as ColumnDefinition[];

const LicensesList: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({ onError }) => {
  const { session } = useStore();

  const transformData = (response: {
    [key: string]: { seatsTaken: number; maxSeats: number };
  }): {
    count: number;
    results: GridValidRowModel[];
  } => ({
    count: Object.keys(response).length,
    results: Object.keys(response)
      .filter((name) => name !== 'protectorNotify')
      // if hasEventsLicense is true, show events license and hide incidents license
      .filter((name) => (session.hasEventsLicense ? name !== 'protectorIncidents' : name !== EntityModules.Events))
      .map((name) => ({
        ...response[name],
        name,
        uuid: name,
        overview: { taken: response[name].seatsTaken, max: response[name].maxSeats },
      })),
  });

  return (
    <Grid
      basicColumns={basicColumns}
      dataURL={`${process.env.REACT_APP_ADMIN_API_URL}licenses/`}
      onError={onError}
      sortBy={{ field: 'name', sort: 'asc' }}
      // @ts-ignore
      transformData={transformData}
      gridPagination={false}
      disableColumnSelector
      showHeader={false}
      showQuickFilter={false}
    />
  );
};

export default observer(LicensesList);
