import styled from 'styled-components';

export const SectionContainer = styled.div`
  gap: 8px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const SectionHeader = styled.div`
  gap: 10px;
  min-height: 32px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  background: rgba(7, 114, 229, 0.04);
  margin: 4px 0;

  p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: #0772e5;
    font-weight: 400;
    text-align: center;
    font-style: normal;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
