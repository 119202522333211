import React from 'react';
import { observer } from 'mobx-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { formatDate, formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import dayjs from 'dayjs';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { Value } from 'containers/IncidentDetails/components/EditableTextInput/styles';
import { JSONSchemaType, LocationAnswerType, ProtectorType } from 'utils/api/types';

import IncidentStatusChip from 'components/Chips/IncidentStatusChip/IncidentStatusChip';

import UserRow from 'components/UserRow';

import EntityDisplayValue from 'components/Entities/EntityDisplayValue';

import { useStore } from 'utils/hooks/store';
import { EntityModules, UserRole } from 'core/constants';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import Logger from 'utils/logger';

import API from 'utils/api/api';

import { CustomFieldDisplayValueProps } from './types';
import { DescriptionField, LocationText } from '../IncidentInformation/styles';
import { EmptyValue } from './styles';
import UserField from '../UserField';

const CustomFieldDisplayValue: React.FunctionComponent<CustomFieldDisplayValueProps> = ({
  schema,
  type,
  value,
  lookupType,
}) => {
  const { session } = useStore();
  const getEmptyValueForType = (protectorType: ProtectorType): string => {
    switch (protectorType) {
      case ProtectorType.User:
        return 'Not assigned';
      case ProtectorType.Location:
        return 'Not selected';
      default:
        return '-';
    }
  };

  const [lookupValue, setLookupValue] = React.useState<UuidableName | undefined>(undefined);

  React.useEffect(() => {
    // we need this function for displaying the name of lookup fields in the original incident tab
    // backend sends only the uuid of the lookup field, so we need to fetch the name of the entity
    const getValue = async (): Promise<void> => {
      if (type === ProtectorType.Lookup) {
        if (typeof value === 'string' && typeof lookupType === 'string') {
          const { singular } = getEntityTranslation[lookupType as EntityModules];
          try {
            const {
              data: { entity },
            } = await API.loadEntitiesDetails(lookupType, value)();
            const nameKey = `${lookupType}__${singular.toLowerCase()}Name`;
            setLookupValue({
              name: (entity[nameKey].value as unknown) as string,
              uuid: value,
            });
          } catch (error) {
            Logger.error(`Failed to load entity details`);
          }
        } else if (typeof value === 'object') {
          setLookupValue(value as UuidableName);
        }
      }
    };

    getValue();
  }, []);

  if (value === null || value === undefined) {
    return <EmptyValue id="empty-value">{getEmptyValueForType(type)}</EmptyValue>;
  }
  switch (type) {
    case ProtectorType.Time:
      return <Value>{dayjs(value as number).format('HH:mm')}</Value>;
    case ProtectorType.DateTime:
      return <Value>{formatDateTime(value as number)}</Value>;
    case ProtectorType.Number:
      return <Value>{value as string}</Value>;
    case ProtectorType.ShortText:
      return <Value id="short-text-value">{value as string}</Value>;
    case ProtectorType.LongText:
      return <DescriptionField id="long-text-value">{value as string}</DescriptionField>;
    case ProtectorType.SingleChoice:
      return <Value id="single-choice-value">{value as string}</Value>;
    case ProtectorType.MultiChoice:
      return <Value id="multi-choice-value">{(value as string[]).join(', ')}</Value>;
    case ProtectorType.Date:
      return <Value id="date-value">{formatDate(value as number)}</Value>;
    case ProtectorType.User:
      if (typeof value === 'object') {
        return <UserRow user={(value as unknown) as UuidableName} />;
      }
      return <UserField userId={(value as string).replace('user:', '')} />;
    case ProtectorType.Status:
      return <IncidentStatusChip schema={schema as JSONSchemaType} label={value as string} />;
    case ProtectorType.Location: {
      const { address, lat, lng } = value as LocationAnswerType;
      if (lat === null && lng === null) {
        return <Value>-</Value>;
      }
      let displayValue = `Latitude: ${lat}, Longitude: ${lng}`;
      if (address) {
        displayValue = address;
      }
      return (
        <>
          <LocationOnIcon color="primary" />
          <LocationText id="location-value">{displayValue}</LocationText>
        </>
      );
    }
    case ProtectorType.Lookup:
      return session.user?.role === UserRole.User ? (
        <Value>{lookupValue?.name}</Value>
      ) : (
        <EntityDisplayValue link={lookupValue?.name} path={`/${lookupType}/${lookupValue?.uuid}`} />
      );
    default:
      return <Value id="not-supported-type">Field type not supported</Value>;
  }
};

export default observer(CustomFieldDisplayValue);
