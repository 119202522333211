import styled from 'styled-components';

export const Text = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.15px;
  margin: 0;
  padding: 0;
  margin-bottom: 24px;
`;

export const SelectTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
`;

export const TitleText = styled.h4`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-top: 16px;
`;

export const SelectedValue = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
`;
