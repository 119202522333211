import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import {
  ActivityListResponse as VatixActivityListResponse,
  BasicRequestConfig,
  MessageActivityResponse,
  OpenRequest,
  QueryParams,
  SearchResponse,
  UserSearchResponse,
  ActivityResponse as VatixActivityResponse,
  PaginationParams,
  ActivityListResponse,
  UuidableName,
  PaginatedResponse,
} from 'vatix-ui/lib/utils/api/types';

import { ResolutionReasonFormValues } from 'containers/ResolutionDialog/types';
import { ActivityMapQueryParams } from 'stores/ActivityMap/types';
import { DeviceListQueryParams } from 'stores/DeviceList/types';
import { UserListQueryParams } from 'stores/UserList/types';
import { LOCATION_HISTORY_PERIOD, NotificationTarget } from 'core/constants';

import { UuidableLocationRedAmberAlert } from 'containers/AlarmDetails/components/AlarmLocation/types';

import {
  EntitySubmissionDraftType,
  PublicSubmissionUserDetails,
} from 'stores/EntitiesFormSubmission/EntitiesFormSubmission';

import {
  AlarmsPaginatedResponse,
  DashboardDetails,
  LoginResponse,
  ActiveUserResponse,
  RequestOptions,
  EditUserResponse,
  EscalationProfilePaginatedResponse,
  UserDetailsResponse,
  CompletedRedAlertResponse,
  ActivityResponse,
  PendingRedAlertResponse,
  LocationHistoryResponse,
  ResolutionReasonResponse,
  OrganisationResponse,
  UserListPaginatedResponse,
  DeviceListPaginatedResponse,
  APIRequest,
  LicensesStatusResponse,
  LocationDetailsResponse,
  DeviceDetailsResponse,
  DeviceHistoryPaginatedResponse,
  IncidentListPaginatedResponse,
  IncidentResponse,
  VoiceMemoDetailsResponse,
  UserProfileResponse,
  OrderingParams,
  AlarmDetailsResponse,
  AlarmResponse,
  DevicesSearchResponse,
  UpdateUserRequest,
  AlarmResponseProfileSearchResponse,
  AmberAlarmPaginatedResponse,
  DeviceAnalyticsType,
  AlarmResponseProfileResponse,
  EscalationProfileCreateARC,
  EscalationProfileDetailsResponse,
  TaskType,
  TaskListResponse,
  TaskListPaginatedResponse,
  NotificationResponse,
  CustomIncidentTypeResponse,
  AnswerType,
  JSONSchemaType,
  NotificationSettingsResponse,
  NotificationSettings,
  UpdateIncidentResponse,
  IncidentType,
  InspectionSearchResponse,
  InspectionQuestionsSearchResponse,
  HomeResponse,
  TeamDetailsResponse,
  TeamAssignedUserResponse,
  TeamResponse,
  UsersCreationParams,
  DeviceUpdateData,
  TemplateDetailsResponse,
  TemplateListResponse,
  DeleteUsersRequest,
  FormsDetailsResponse,
  fusionAuthApplicationData,
  EntityDetailsType,
  EntityFieldType,
  EntityLayoutType,
  CreateTaskData,
  TemplateCreateType,
  TemplateSearchResponse,
  EntityCountType,
  FormUpdateData,
  UsersCreationInBulkParams,
  EntityFormType,
  FormToFieldMappingResponse,
  AnswerTypes,
} from './types';

export default class API {
  static login(data: { username: string; password?: string }): APIRequest<LoginResponse> {
    const url = '/login/session/';
    const method = 'POST';

    return API.request<LoginResponse>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static logout(): APIRequest<void> {
    const url = '/logout/session/';
    const method = 'POST';

    return API.request<void>({
      url,
      method,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static getFusionAuthApplicationData(params: { username: string }): APIRequest<fusionAuthApplicationData> {
    const url = '/fusionauth/application/';
    const method = 'GET';

    return API.request<fusionAuthApplicationData>({
      url,
      method,
      params,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static changePassword(data: {
    oldPassword?: string;
    newPassword1?: string;
    newPassword2?: string;
  }): APIRequest<void> {
    const url = '/user/password-change/';
    const method = 'POST';

    return API.request<void>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static profile(): APIRequest<UserProfileResponse> {
    const url = '/user/';
    const method = 'GET';

    return API.request<UserProfileResponse>({
      url,
      method,
    });
  }

  static createUser(data: UsersCreationParams): APIRequest<UsersCreationParams> {
    const url = '/users/single/';
    const method = 'POST';

    return API.request<UsersCreationParams>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static createUserInBulk(data: UsersCreationInBulkParams): APIRequest<UsersCreationInBulkParams> {
    const url = '/users/import/';
    const method = 'POST';

    return API.request<UsersCreationInBulkParams>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static loadMasterServiceAgreement(): APIRequest<UsersCreationParams> {
    const url = '/organisations/organisation-document/';
    const method = 'GET';

    return API.request<UsersCreationParams>({
      url,
      method,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static loadUsers(params: UserListQueryParams): APIRequest<UserListPaginatedResponse> {
    const url = '/users/';
    const method = 'GET';

    return API.request<UserListPaginatedResponse>({
      url,
      method,
      params,
    });
  }

  static loadUserData(userId: string): APIRequest<EditUserResponse> {
    const url = `/users/${userId}/`;
    const method = 'GET';

    return API.request<EditUserResponse>({
      url,
      method,
    });
  }

  static loadUserDataForManagers(userId: string): APIRequest<EditUserResponse> {
    const url = `/users/${userId}/user_for_managers`;
    const method = 'GET';

    return API.request<EditUserResponse>({
      url,
      method,
    });
  }

  static loadUserDetails(userId: string): APIRequest<UserDetailsResponse> {
    const url = `/users/${userId}/details/`;
    const method = 'GET';

    return API.request<UserDetailsResponse>({
      url,
      method,
    });
  }

  static loadUserDetailsForManagers(userId: string): APIRequest<UserDetailsResponse> {
    const url = `/users/${userId}/details_for_managers/`;
    const method = 'GET';

    return API.request<UserDetailsResponse>({
      url,
      method,
    });
  }

  static updateUser(userId: string, data: Partial<UpdateUserRequest>): APIRequest<EditUserResponse> {
    const url = `/users/${userId}/`;
    const method = 'PATCH';

    return API.request<EditUserResponse>({
      url,
      method,
      data,
    });
  }

  static deleteUsers(data: Partial<DeleteUsersRequest>): APIRequest<void> {
    const url = `/users/`;
    const method = 'DELETE';

    return API.request<void>({
      url,
      method,
      data,
    });
  }

  static userCustomIncidentType(): APIRequest<CustomIncidentTypeResponse> {
    const url = `/users/custom/type/`;
    const method = 'GET';

    return API.request<CustomIncidentTypeResponse>({
      url,
      method,
    });
  }

  static updateUserData(
    userId: string,
    data: Partial<EditUserResponse> | { tags: { name: string }[] }
  ): APIRequest<EditUserResponse> {
    const url = `/users/${userId}/`;
    const method = 'PATCH';

    return API.request<EditUserResponse>({
      url,
      method,
      data,
    });
  }

  static loadDeviceActivities(deviceId: string, params: PaginationParams): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/devices/${deviceId}/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static loadIncidentActivities(incidentId: string, params: PaginationParams): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/incidents/${incidentId}/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static exportAlarm(incidentId: string, params: OrderingParams | QueryParams): OpenRequest {
    const url = `/api/alarms/${incidentId}/export/pdf/`;

    return API.open({
      url,
      params,
    });
  }

  static exportIncident(incidentId: string, params: OrderingParams | QueryParams): OpenRequest {
    const url = `/api/incidents/${incidentId}/export/pdf/`;

    return API.open({
      url,
      params,
    });
  }

  static loadActiveUsers(params: ActivityMapQueryParams, timeout?: number): APIRequest<ActiveUserResponse[]> {
    const url = '/users/active/';
    const method = 'GET';

    return API.request<ActiveUserResponse[]>({
      url,
      method,
      params,
      timeout,
    });
  }

  static loadPendingRedAlerts(): APIRequest<PendingRedAlertResponse[]> {
    const url = '/alerts/red/pending/';
    const method = 'GET';

    return API.request<PendingRedAlertResponse[]>({
      url,
      method,
    });
  }

  static loadAlarms(params: OrderingParams | QueryParams): APIRequest<AlarmsPaginatedResponse> {
    const url = '/alarms/';
    const method = 'GET';

    return API.request<AlarmsPaginatedResponse>({
      url,
      method,
      params,
    });
  }

  static loadAmberAlarms(params: OrderingParams | QueryParams): APIRequest<AmberAlarmPaginatedResponse> {
    const url = '/alarms/timed/';
    const method = 'GET';

    return API.request<AmberAlarmPaginatedResponse>({
      url,
      method,
      params,
    });
  }

  static loadAlarmDetails(alarmId: string): APIRequest<AlarmDetailsResponse> {
    const url = `/alarms/${alarmId}/`;
    const method = 'GET';

    return API.request<AlarmDetailsResponse>({
      url,
      method,
    });
  }

  static downloadAllRowsToCSV(url: string, params?: OrderingParams | QueryParams): OpenRequest {
    return API.open({
      url,
      params,
    });
  }

  static sendExportEmail(url: string): APIRequest<void> {
    const method = 'POST';

    return API.request<void>({
      url,
      method,
    });
  }

  static acceptRedAlert(alertId: string): APIRequest<AlarmResponse> {
    const url = `/alerts/red/pending/${alertId}/`;
    const method = 'PUT';

    return API.request<AlarmResponse>({
      url,
      method,
    });
  }

  static ignoreRedAlert(alertId: string): APIRequest<void> {
    const url = `/alerts/red/pending/${alertId}/`;
    const method = 'DELETE';

    return API.request<void>({
      url,
      method,
    });
  }

  static completeRedAlert(
    alertId: string,
    resolutionReason: ResolutionReasonFormValues
  ): APIRequest<CompletedRedAlertResponse> {
    const url = `/alerts/red/accepted/${alertId}/`;
    const method = 'PUT';
    const data = {
      ...resolutionReason,
    };

    return API.request<CompletedRedAlertResponse>({
      url,
      method,
      data,
    });
  }

  static loadResolutionReasons(): APIRequest<ResolutionReasonResponse[]> {
    const url = '/alerts/red/resolution-reasons/';
    const method = 'GET';

    return API.request<ResolutionReasonResponse[]>({
      url,
      method,
    });
  }

  static authWebsocket(socketId: string, channelName: string): APIRequest<{ auth: string }> {
    const url = '/websocket/auth/';
    const method = 'POST';
    const data = {
      socketId,
      channelName,
    };

    return API.request<{ auth: string }>({
      url,
      method,
      data,
    });
  }

  static addDeviceMessage(deviceId: string, message: string): APIRequest<MessageActivityResponse> {
    const url = `/devices/${deviceId}/comments/`;
    const method = 'POST';

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message },
    });
  }

  static updateDeviceMessage(
    deviceId: string,
    messageId: string,
    message: string
  ): APIRequest<MessageActivityResponse> {
    const url = `/devices/${deviceId}/comments/${messageId}/`;
    const method = 'PUT';

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message },
    });
  }

  static removeDeviceMessage(deviceId: string, messageId: string): APIRequest<MessageActivityResponse> {
    const url = `/devices/${deviceId}/comments/${messageId}/`;
    const method = 'DELETE';

    return API.request<MessageActivityResponse>({
      url,
      method,
    });
  }

  static uploadDeviceFile(
    deviceId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<VatixActivityResponse> {
    const method = 'POST';
    const url = `/devices/${deviceId}/activities/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<VatixActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static removeDeviceFile(deviceId: string, fileId: string): APIRequest<void> {
    const url = `/devices/${deviceId}/activities/files/${fileId}/`;
    const method = 'DELETE';

    return API.request<void>({
      url,
      method,
    });
  }

  static loadOrganisations(): APIRequest<OrganisationResponse[]> {
    const url = '/organisations/';
    const method = 'GET';

    return API.request<OrganisationResponse[]>({
      url,
      method,
    });
  }

  static loadOrganisationLogo(): APIRequest<Blob> {
    const url = '/organisations/logo/';
    const method = 'GET';

    return API.request<Blob>({
      url,
      method,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
      responseType: 'blob',
    });
  }

  static uploadLogoFile(file: File, cancelTokenSource: CancelTokenSource): APIRequest<ActivityResponse> {
    const method = 'PUT';
    const url = `/organisations/logo/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      cancelTokenSource,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static deleteLogoFile(): APIRequest<ActivityResponse> {
    const method = 'DELETE';
    const url = `/organisations/logo/`;

    return API.request<ActivityResponse>({
      url,
      method,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static loadDeviceList(params: DeviceListQueryParams): APIRequest<DeviceListPaginatedResponse> {
    const url = '/devices/';
    const method = 'GET';

    return API.request<DeviceListPaginatedResponse>({
      url,
      method,
      params,
    });
  }

  static loadDeviceAnalytics(params: QueryParams): APIRequest<DeviceAnalyticsType[]> {
    const url = '/devices/analytics/';
    const method = 'GET';

    return API.request<DeviceAnalyticsType[]>({
      url,
      method,
      params,
    });
  }

  static updateDevice(deviceId: string, data: DeviceUpdateData): APIRequest<DeviceDetailsResponse> {
    const url = `/devices/${deviceId}/`;
    const method = 'PATCH';

    return API.request<DeviceDetailsResponse>({
      url,
      method,
      data,
    });
  }

  static loadDeviceDetails(deviceId: string): APIRequest<DeviceDetailsResponse> {
    const url = `/devices/${deviceId}/`;
    const method = 'GET';

    return API.request<DeviceDetailsResponse>({
      url,
      method,
    });
  }

  static searchInspectionQuestion(
    inspectionId: string,
    params?: Partial<{ query: string }>
  ): APIRequest<InspectionQuestionsSearchResponse[]> {
    const url = `/inspections/${inspectionId}/questions/search/`;
    const method = 'GET';

    return API.request<InspectionQuestionsSearchResponse[]>({
      url,
      method,
      params,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static searchTemplates(
    params?: Partial<{
      query: string;
      limit: number;
    }>
  ): APIRequest<TemplateSearchResponse[]> {
    const url = '/templates/search/';
    const method = 'GET';

    return API.request<TemplateSearchResponse[]>({
      url,
      method,
      params,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static createInspection({ templateVersion, name, site, asset }: TemplateCreateType): APIRequest<UuidableName> {
    const url = '/inspections/';
    const method = 'POST';

    const data = { templateVersion, name, site, asset };

    return API.request<UuidableName>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static searchInspections(
    params?: Partial<{
      query: string;
      limit: number;
    }>
  ): APIRequest<InspectionSearchResponse[]> {
    const url = '/inspections/search/';
    const method = 'GET';

    return API.request<InspectionSearchResponse[]>({
      url,
      method,
      params,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static searchEntity(
    entity: string,
    organisationId: string | undefined,
    params?: Partial<{ query: string; limit: number; offset: number; type?: IncidentType }>
  ): APIRequest<SearchResponse[]> {
    const url = organisationId ? `/entities/public/${organisationId}/${entity}/search/` : `/entities/${entity}/search/`;
    const method = 'GET';

    return API.request<SearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static searchIncidents(
    params?: Partial<{ query: string; limit: number; offset: number; type?: IncidentType }>
  ): APIRequest<SearchResponse[]> {
    const url = '/incidents/search/';
    const method = 'GET';

    return API.request<SearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static searchUsers(params?: Partial<{ query: string; limit: number }>): APIRequest<UserSearchResponse[]> {
    const url = '/users/search/';
    const method = 'GET';

    return API.request<UserSearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static searchDevices(
    params?: Partial<{
      query: string;
      // eslint-disable-next-line camelcase
      excluded_group?: string;
      limit: number;
      assigned?: boolean;
    }>
  ): APIRequest<DevicesSearchResponse[]> {
    const url = '/devices/search/';
    const method = 'GET';

    return API.request({
      url,
      method,
      params,
    });
  }

  static searchDeviceTags(
    params?: Partial<{
      query: string;
      limit: number;
    }>
  ): APIRequest<SearchResponse[]> {
    const url = '/devices/tags/search/';
    const method = 'GET';

    return API.request<SearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static deviceTagsDetails(uuid: string): APIRequest<UuidableName> {
    const url = `/devices/tags/${uuid}/`;
    const method = 'GET';

    return API.request<UuidableName>({
      url,
      method,
    });
  }

  static assignedDevicesUsers(
    params?: Partial<{
      query: string;
      limit: number;
    }>
  ): APIRequest<SearchResponse[]> {
    const url = '/devices/users/search/';
    const method = 'GET';

    return API.request<SearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static searchGroups(
    params?: Partial<{ query: string; organisation: string; limit: number }>
  ): APIRequest<SearchResponse[]> {
    const url = '/groups/search/';
    const method = 'GET';

    return API.request<SearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static searchOrganisations(
    params?: Partial<{ query: string; organisation: string; limit: number }>
  ): APIRequest<SearchResponse[]> {
    const url = '/organisations/search/';
    const method = 'GET';

    return API.request<SearchResponse[]>({
      url,
      method,
      params,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static searchAlarmResponseProfiles(
    params?: Partial<{ query: string; organisation: string; limit: number }>
  ): APIRequest<AlarmResponseProfileSearchResponse[]> {
    const url = '/alarms/escalation-profiles/search/';
    const method = 'GET';

    return API.request<AlarmResponseProfileSearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static loadLocationDetails(locationId: string): APIRequest<LocationDetailsResponse> {
    const url = `/locations/${locationId}/`;
    const method = 'GET';

    return API.request<LocationDetailsResponse>({
      url,
      method,
    });
  }

  static loadDeviceLocations(
    deviceId: string,
    params: { period?: keyof typeof LOCATION_HISTORY_PERIOD },
    timeout?: number
  ): APIRequest<LocationHistoryResponse[]> {
    const url = `/devices/${deviceId}/locations/`;
    const method = 'GET';

    return API.request<LocationHistoryResponse[]>({
      url,
      method,
      params,
      timeout,
    });
  }

  static loadAlarmLocations(alarmId: string): APIRequest<PaginatedResponse<UuidableLocationRedAmberAlert>> {
    const url = `/alarms/${alarmId}/locations/`;
    const method = 'GET';

    return API.request<PaginatedResponse<UuidableLocationRedAmberAlert>>({
      url,
      method,
      params: {
        limit: 10000,
      },
    });
  }

  static loadDeviceHistory(
    deviceId: string,
    params?: OrderingParams | QueryParams
  ): APIRequest<DeviceHistoryPaginatedResponse> {
    const url = `/devices/${deviceId}/assignments/`;
    const method = 'GET';

    return API.request<DeviceHistoryPaginatedResponse>({
      url,
      method,
      params,
    });
  }

  static searchUserTags(
    params?: Partial<{
      query: string;
      limit: number;
    }>
  ): APIRequest<SearchResponse[]> {
    const url = '/users/tags/search/';
    const method = 'GET';

    return API.request<SearchResponse[]>({
      url,
      method,
      params,
    });
  }

  static userTagsDetails(uuid: string): APIRequest<UuidableName> {
    const url = `/users/tags/${uuid}/`;
    const method = 'GET';

    return API.request<UuidableName>({
      url,
      method,
    });
  }

  static loadIncidents(
    params?: OrderingParams | QueryParams | ActivityMapQueryParams
  ): APIRequest<IncidentListPaginatedResponse> {
    const url = '/incidents/';
    const method = 'GET';

    return API.request<IncidentListPaginatedResponse>({
      url,
      method,
      params,
    });
  }

  static createCustomIncident(data: {
    details: { content: AnswerType; schema: JSONSchemaType };
    site?: string;
    asset?: string;
  }): APIRequest<IncidentResponse> {
    const url = '/incidents/custom/';
    const method = 'POST';

    return API.request<IncidentResponse>({
      url,
      method,
      data,
    });
  }

  static updateIncident(incidentId: string, data: UpdateIncidentResponse): APIRequest<IncidentResponse> {
    const url = `/incidents/${incidentId}/`;
    const method = 'PATCH';

    return API.request<IncidentResponse>({
      url,
      method,
      data,
    });
  }

  static loadIncidentDetails(incidentId: string): APIRequest<IncidentResponse> {
    const url = `/incidents/${incidentId}`;
    const method = 'GET';

    return API.request<IncidentResponse>({
      url,
      method,
    });
  }

  static loadPublicSubmission(organisationId: string): APIRequest<CustomIncidentTypeResponse> {
    const url = `/incidents/public/${organisationId}/`;
    const method = 'GET';

    return API.request<CustomIncidentTypeResponse>({
      url,
      method,
    });
  }

  static createPublicSubmission(
    data: { submission: AnswerType; schema: JSONSchemaType; site?: string; asset?: string },
    organisationId: string
  ): APIRequest<IncidentResponse> {
    const url = `/incidents/public/${organisationId}/`;
    const method = 'POST';

    return API.request<IncidentResponse>({
      url,
      method,
      data,
    });
  }

  static loadVoiceMemoDetails(voiceMemoId: string): APIRequest<VoiceMemoDetailsResponse> {
    const url = `/voice-memos/${voiceMemoId}/`;
    const method = 'GET';

    return API.request<VoiceMemoDetailsResponse>({
      url,
      method,
    });
  }

  static addAlarmMessage(alarmId: string, message: string): APIRequest<MessageActivityResponse> {
    const url = `/alarms/${alarmId}/comments/`;
    const method = 'POST';

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message },
    });
  }

  static updateAlarmMessage(alarmId: string, messageId: string, message: string): APIRequest<MessageActivityResponse> {
    const url = `/alarms/${alarmId}/comments/${messageId}/`;
    const method = 'PUT';

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message },
    });
  }

  static downloadFile(url: string): OpenRequest {
    return API.open({ url, finalURL: url });
  }

  static removeAlarmMessage(alarmId: string, messageId: string): APIRequest<MessageActivityResponse> {
    const url = `/alarms/${alarmId}/comments/${messageId}/`;
    const method = 'DELETE';

    return API.request<MessageActivityResponse>({
      url,
      method,
    });
  }

  static uploadIncidentFile(
    incidentId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<ActivityResponse> {
    const method = 'POST';
    const url = `/incidents/${incidentId}/activities/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static addReporterActivitiesMessage(
    incidentId: string,
    message: string,
    initialSubmission: boolean,
    taggedUsers?: UserSearchResponse[]
  ): APIRequest<MessageActivityResponse> {
    const method = 'POST';
    const url = `/incidents/${incidentId}/submission/comments/`;

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message, taggedUsers, initialSubmission },
    });
  }

  static uploadReporterActivitiesFile(
    incidentId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource,
    initialSubmission: boolean
  ): APIRequest<ActivityResponse> {
    const method = 'POST';
    const url = `/incidents/${incidentId}/submission/files/`;

    const data = new FormData();
    data.append('file', file);
    data.append('initial_submission', String(initialSubmission));

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static loadIncidentReporterActivities(
    incidentId: string,
    params: PaginationParams
  ): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/incidents/${incidentId}/submission/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static uploadAlarmFile(
    alarmId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<ActivityResponse> {
    const method = 'POST';
    const url = `/alarms/${alarmId}/activities/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static removeAlarmFile(alarmId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/alarms/${alarmId}/activities/files/${fileId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static removeIncidentFile(taskId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/incidents/${taskId}/activities/files/${fileId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static removeReporterIncidentFile(taskId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/incidents/${taskId}/submission/files/${fileId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static loadAlarmActivities(alarmId: string, params: PaginationParams): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/alarms/${alarmId}/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static createEscalationProfiles(data: EscalationProfileCreateARC): APIRequest<AlarmResponseProfileResponse> {
    const method = 'POST';
    const url = `/alarms/escalation-profiles/`;

    return API.request<AlarmResponseProfileResponse>({
      url,
      method,
      data,
    });
  }

  static loadEscalationProfiles(params: OrderingParams | QueryParams): APIRequest<EscalationProfilePaginatedResponse> {
    const method = 'GET';
    const url = `/alarms/escalation-profiles/`;

    return API.request<EscalationProfilePaginatedResponse>({
      url,
      method,
      params,
    });
  }

  static loadEscalationProfileDetails(escalationProfileId: string): APIRequest<AlarmResponseProfileResponse> {
    const method = 'GET';
    const url = `/alarms/escalation-profiles/${escalationProfileId}/`;

    return API.request<AlarmResponseProfileResponse>({
      url,
      method,
    });
  }

  static deleteAlarmResponseProfile(escalationProfileId: string): APIRequest<void> {
    const url = `/alarms/escalation-profiles/${escalationProfileId}/`;
    const method = 'DELETE';

    return API.request<void>({
      url,
      method,
    });
  }

  static updateEscalationProfileDetails(
    data: EscalationProfileDetailsResponse
  ): APIRequest<AlarmResponseProfileResponse> {
    const method = 'PATCH';
    const url = `/alarms/escalation-profiles/${data.uuid}/`;

    return API.request<AlarmResponseProfileResponse>({
      url,
      method,
      data,
    });
  }

  static loadLicensesStatus(): APIRequest<LicensesStatusResponse> {
    const url = '/organisations/licenses/';
    const method = 'GET';

    return API.request<LicensesStatusResponse>({
      url,
      method,
    });
  }

  static addIncidentMessage(
    incidentId: string,
    message: string,
    taggedUsers?: UserSearchResponse[]
  ): APIRequest<MessageActivityResponse> {
    const url = `/incidents/${incidentId}/comments/`;
    const method = 'POST';

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message, taggedUsers },
    });
  }

  static updateIncidentMessage(
    incidentId: string,
    messageId: string,
    message: string
  ): APIRequest<MessageActivityResponse> {
    const url = `/incidents/${incidentId}/comments/${messageId}/`;
    const method = 'PUT';

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message },
    });
  }

  static removeIncidentMessage(incidentId: string, messageId: string): APIRequest<MessageActivityResponse> {
    const url = `/incidents/${incidentId}/comments/${messageId}/`;
    const method = 'DELETE';

    return API.request<MessageActivityResponse>({
      url,
      method,
    });
  }

  static removeReporterIncidentMessage(incidentId: string, messageId: string): APIRequest<MessageActivityResponse> {
    const url = `/incidents/${incidentId}/submission/comments/${messageId}/`;
    const method = 'DELETE';

    return API.request<MessageActivityResponse>({
      url,
      method,
    });
  }

  static loadDashboard(dashboardId: string): APIRequest<DashboardDetails> {
    const url = `/dashboards/${dashboardId}/`;
    const method = 'GET';

    return API.request<DashboardDetails>({
      url,
      method,
    });
  }

  static createTask(data: TaskType): APIRequest<TaskType> {
    const url = `/tasks/`;
    const method = 'POST';

    return API.request<TaskType>({
      url,
      method,
      data,
    });
  }

  static updateTask(taskId: string, data: Partial<TaskListResponse>): APIRequest<TaskListResponse> {
    const url = `/tasks/${taskId}/`;
    const method = 'PATCH';
    return API.request<TaskListResponse>({
      url,
      method,
      data,
    });
  }

  static deleteTask(taskId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/tasks/${taskId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static loadTaskDetails(taskId: string): APIRequest<TaskListResponse> {
    const url = `/tasks/${taskId}/`;
    const method = 'GET';

    return API.request<TaskListResponse>({
      url,
      method,
    });
  }

  static loadActivitiesTask(taskId: string, params: PaginationParams): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/tasks/${taskId}/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static addTaskMessage(
    taskId: string,
    message: string,
    taggedUsers?: UserSearchResponse[]
  ): APIRequest<MessageActivityResponse> {
    const method = 'POST';
    const url = `/tasks/${taskId}/comments/`;

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message, taggedUsers },
    });
  }

  static uploadTaskFile(
    taskId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<VatixActivityResponse> {
    const method = 'POST';
    const url = `/tasks/${taskId}/activities/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<VatixActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static removeTaskFile(taskId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/tasks/${taskId}/activities/files/${fileId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static removeTaskMessage(taskId: string, messageId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/tasks/${taskId}/comments/${messageId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static addPublicReporterActivitiesMessage(
    organisationId: string,
    incidentId: string,
    message: string,
    initialSubmission: boolean,
    taggedUsers?: UserSearchResponse[]
  ): APIRequest<MessageActivityResponse> {
    const method = 'POST';
    const url = `/incidents/public/${organisationId}/incidents/${incidentId}/comments/`;

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message, taggedUsers, initialSubmission },
    });
  }

  static uploadPublicReporterActivitiesFile(
    incidentId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource,
    initialSubmission: boolean,
    organisationId: string
  ): APIRequest<ActivityResponse> {
    const method = 'POST';
    const url = `/incidents/public/${organisationId}/incidents/${incidentId}/files/`;

    const data = new FormData();
    data.append('file', file);
    data.append('initial_submission', String(initialSubmission));

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static loadNotifications(params: PaginationParams & { target: string }): APIRequest<NotificationResponse> {
    const url = '/user/notifications/';
    const method = 'GET';

    return API.request<NotificationResponse>({
      url,
      method,
      params,
    });
  }

  static dismissNotifications(data: string[]): APIRequest<void> {
    const url = '/user/notifications/';
    const method = 'PUT';
    return API.request<void>({
      url,
      method,
      data,
    });
  }

  static loadNotificationSettings(): APIRequest<NotificationSettingsResponse> {
    const method = 'GET';
    const url = '/user/notifications/settings/';

    return API.request<NotificationSettingsResponse>({
      url,
      method,
    });
  }

  static updateNotificationSetting(
    notificationTarget: NotificationTarget,
    settings: Partial<NotificationSettings>
  ): APIRequest<NotificationSettings> {
    const method = 'PATCH';
    const url = `/user/notifications/settings/${notificationTarget}/`;

    return API.request<NotificationSettings>({
      url,
      method,
      data: settings,
    });
  }

  static loadTeamDetails(teamId: string): APIRequest<TeamDetailsResponse> {
    const url = `/teams/${teamId}/`;
    const method = 'GET';

    return API.request<TeamDetailsResponse>({
      url,
      method,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static createTeam(data: { name: string; members: TeamAssignedUserResponse[] }): APIRequest<TeamResponse> {
    const url = '/teams/';
    const method = 'POST';

    return API.request<TeamResponse>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static updateTeam(
    teamId: string,
    data: { name: string; members: TeamAssignedUserResponse[] }
  ): APIRequest<TeamDetailsResponse> {
    const url = `/teams/${teamId}/`;
    const method = 'PATCH';

    return API.request<TeamDetailsResponse>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static deleteTeam(taskId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/teams/${taskId}/`;

    return API.request<void>({
      url,
      method,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static request<T>(options: RequestOptions): APIRequest<T> {
    const {
      baseURL = process.env.REACT_APP_API_URL,
      url,
      method,
      params = {},
      data = {},
      headers = {},
      authorization,
      timeout = undefined,
      onUploadProgress = undefined,
      cancelTokenSource = undefined,
      responseType = undefined,
    } = options;

    if (authorization) {
      headers.authorization = authorization;
    }
    const config = {
      method,
      url,
      params,
      data,
      headers,
      onUploadProgress,
      timeout,
      baseURL,
      withCredentials: true,
      xsrfHeaderName: 'X-CSRFToken',
      xsrfCookieName: process.env.REACT_APP_CSRF_COOKIE_NAME || 'csrftoken',
      cancelToken: cancelTokenSource?.token,
      responseType,
      exposedHeaders: ['Location'],
    };
    const axiosRequest = (): Promise<AxiosResponse<T>> => axios(config);
    axiosRequest.config = config;

    return axiosRequest;
  }

  static open(config: BasicRequestConfig): OpenRequest {
    const { baseURL = process.env.REACT_APP_API_URL, params = {}, url } = config;
    let { finalURL } = config;
    if (!finalURL) {
      const queryString = new URLSearchParams();
      Object.entries(params).forEach(([name, value]) => queryString.append(name, value));
      const buildUrl = new URL(url, baseURL);
      // @ts-ignore
      buildUrl.search = queryString;
      finalURL = buildUrl.toString();
    }
    const result = (() => window.open(finalURL) as unknown) as OpenRequest;
    result.config = config;
    return result;
  }

  static verifyPhoneNumber(data: { phoneNumber: string }, authorization?: string): APIRequest<void> {
    const url = '/verifications/phone/2fa/';
    const method = 'POST';

    return API.request<void>({
      url,
      method,
      data,
      authorization,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static confirmVerificationCode(
    data: { phoneNumber: string; verificationCode: string },
    authorization?: string
  ): APIRequest<UserProfileResponse> {
    const url = '/register/2fa/';
    const method = 'POST';

    return API.request<UserProfileResponse>({
      url,
      method,
      data,
      authorization,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static confirmLoginCode(data: { token: string }, authorization?: string): APIRequest<UserProfileResponse> {
    const url = '/login/session/2fa/';
    const method = 'POST';

    return API.request<UserProfileResponse>({
      url,
      method,
      data,
      authorization,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static resendLoginCode(authorization?: string): APIRequest<void> {
    const url = '/login/session/2fa/resend/';
    const method = 'POST';

    return API.request<void>({
      url,
      method,
      authorization,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static resetPassword(data: { email: string }): APIRequest<void> {
    const url = '/user/password-reset/';
    const method = 'POST';

    return API.request<void>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_ADMIN_API_URL,
    });
  }

  static loadHomeContent(): APIRequest<HomeResponse> {
    const url = '/home/';
    const method = 'GET';

    return API.request<HomeResponse>({
      url,
      method,
    });
  }

  static loadFormsDetails(inspectionId: string): APIRequest<FormsDetailsResponse> {
    const url = `/inspections/${inspectionId}/`;
    const method = 'GET';

    return API.request<FormsDetailsResponse>({
      url,
      method,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static deleteForm(inspectionId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/inspections/${inspectionId}/`;

    return API.request<void>({
      url,
      method,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static updateForm(formId: string, data: FormUpdateData): APIRequest<FormsDetailsResponse> {
    const url = `/inspections/${formId}/`;
    const method = 'PATCH';

    return API.request<FormsDetailsResponse>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static addAnswerMessage(
    inspectionId: string,
    answerId: string,
    message: string
  ): APIRequest<MessageActivityResponse> {
    const method = 'POST';
    const url = `/inspections/${inspectionId}/questions/${answerId}/comments/`;

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message },
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static removeAnswerMessage(inspectionId: string, answerId: string, commentId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/inspections/${inspectionId}/questions/${answerId}/comments/${commentId}/`;

    return API.request<void>({
      url,
      method,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static uploadInspectionFile(
    inspectionId: string,
    answerId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<ActivityResponse> {
    const method = 'POST';
    const url = `/inspections/${inspectionId}/questions/${answerId}/activities/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static removeInspectionFile(inspectionId: string, answerId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/inspections/${inspectionId}/questions/${answerId}/activities/files/${fileId}/`;

    return API.request<void>({
      url,
      method,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static loadTasksAnswer(inspection: string, question: string): APIRequest<TaskListPaginatedResponse> {
    const method = 'GET';
    const url = `/tasks/`;

    return API.request<TaskListPaginatedResponse>({
      url,
      method,
      params: { inspection, question },
    });
  }

  static createTaskForForm(data: CreateTaskData): APIRequest<TaskListResponse> {
    const url = '/tasks/';
    const method = 'POST';

    return API.request<TaskListResponse>({
      url,
      method,
      data,
    });
  }

  static downloadFormPDFReport(inspectionId: string): OpenRequest {
    const url = `/api/inspections/${inspectionId}/export/pdf/`;
    return API.open({ url, baseURL: process.env.REACT_APP_WORKFLOWS_API_URL });
  }

  static loadActivitiesAnswer(
    inspectionId: string,
    answerId: string,
    params: PaginationParams
  ): APIRequest<ActivityListResponse> {
    const method = 'GET';
    const url = `/inspections/${inspectionId}/questions/${answerId}/activities/`;

    return API.request<ActivityListResponse>({
      url,
      method,
      params,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static loadTemplateDetails(templateId: string): APIRequest<TemplateDetailsResponse> {
    const url = `/templates/${templateId}/`;
    const method = 'GET';

    return API.request<TemplateDetailsResponse>({
      url,
      method,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static createTemplate(data: { name: string; content: object }): APIRequest<TemplateListResponse> {
    const url = '/templates/';
    const method = 'POST';

    return API.request<TemplateListResponse>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  static updateTemplate(
    templateId: string,
    data: { name?: string; isActive?: boolean; content?: object }
  ): APIRequest<TemplateDetailsResponse> {
    const url = `/templates/${templateId}/`;
    const method = 'PATCH';

    return API.request<TemplateDetailsResponse>({
      url,
      method,
      data,
      baseURL: process.env.REACT_APP_WORKFLOWS_API_URL,
    });
  }

  // entities
  static loadEntitiesDetails<T = EntityDetailsType>(entityType: string, uuid: string): APIRequest<T> {
    const url = `/entities/${entityType}/${uuid}/`;
    const method = 'GET';

    return API.request<T>({
      url,
      method,
    });
  }

  static createEntity(entityType: string, entityFieldValues: { [key: string]: string }): APIRequest<EntityDetailsType> {
    const url = `/entities/${entityType}/`;
    const method = 'POST';

    return API.request<EntityDetailsType>({
      url,
      method,
      data: { entityType, entityFieldValues },
    });
  }

  static deleteEntity(entityType: string, uuid: string): APIRequest<void> {
    const url = `/entities/${entityType}/${uuid}/`;
    const method = 'DELETE';

    return API.request<void>({
      url,
      method,
    });
  }

  static editEntity<T = EntityDetailsType>(
    entityType: string,
    uuid: string,
    data: Record<string, string | number | null | {}>
  ): APIRequest<T> {
    const url = `/entities/${entityType}/${uuid}/`;
    const method = 'PATCH';

    return API.request<T>({
      url,
      method,
      data: { entityFieldValues: data },
    });
  }

  static createEntityField(entityType: string, data: Partial<EntityFieldType>): APIRequest<EntityFieldType> {
    const url = `/entities/${entityType}/fields/`;
    const method = 'POST';

    return API.request<EntityFieldType>({
      url,
      method,
      data,
    });
  }

  static editEntityField(
    entityType: string,
    fieldId: string,
    data: Partial<EntityFieldType>
  ): APIRequest<EntityFieldType> {
    const url = `/entities/${entityType}/fields/${fieldId}/`;
    const method = 'PATCH';

    return API.request<EntityFieldType>({
      url,
      method,
      data,
    });
  }

  static deleteEntityField(fieldType: string, fieldId: string): APIRequest<void> {
    const url = `/entities/${fieldType}/fields/${fieldId}/`;
    const method = 'DELETE';

    return API.request<void>({
      url,
      method,
    });
  }

  static loadEntityFields(
    entityType: string,
    params: PaginationParams
  ): APIRequest<PaginatedResponse<EntityFieldType>> {
    const url = `/entities/${entityType}/fields/?limit=${params.limit}&offset=${params.offset}`;
    const method = 'GET';

    return API.request<PaginatedResponse<EntityFieldType>>({
      url,
      method,
    });
  }

  static loadEntityLayout(entityType: string): APIRequest<{ layout: EntityLayoutType; createdAt: string }> {
    const url = `/entities/${entityType}/layout/`;
    const method = 'GET';

    return API.request<{ layout: EntityLayoutType; createdAt: string }>({
      url,
      method,
    });
  }

  static updateEntityLayout(
    entityType: string,
    data: EntityLayoutType
  ): APIRequest<{ layout: EntityLayoutType; createdAt: string }> {
    const url = `/entities/${entityType}/layout/`;
    const method = 'POST';

    return API.request<{ layout: EntityLayoutType; createdAt: string }>({
      url,
      method,
      data: { layout: data },
    });
  }

  static loadEntityForm(entityType: string, formId: string): APIRequest<EntityFormType> {
    const url = `/entities/${entityType}/forms/${formId}/`;
    const method = 'GET';

    return API.request<EntityFormType>({
      url,
      method,
    });
  }

  static loadFormToFieldMapping(entityType: string, formId: string): APIRequest<FormToFieldMappingResponse> {
    const url = `/entities/${entityType}/forms/${formId}/rules/`;
    const method = 'GET';

    return API.request<FormToFieldMappingResponse>({
      url,
      method,
    });
  }

  static saveFormToFieldMapping(
    entityType: string,
    formId: string,
    data: FormToFieldMappingResponse
  ): APIRequest<void> {
    const url = `/entities/${entityType}/forms/${formId}/rules/`;
    const method = 'PUT';

    return API.request<void>({
      url,
      method,
      data,
    });
  }

  static createEntityForm(entityType: string, data: Partial<EntityFormType>): APIRequest<EntityFormType> {
    const url = `/entities/${entityType}/forms/`;
    const method = 'POST';

    return API.request<EntityFormType>({
      url,
      method,
      data,
    });
  }

  static getEntityForms(entityType: string, params: QueryParams): APIRequest<PaginatedResponse<EntityFormType>> {
    const url = `/entities/${entityType}/forms/`;
    const method = 'GET';

    return API.request<PaginatedResponse<EntityFormType>>({
      url,
      method,
      params,
    });
  }

  static getEntityFormsDrafts(entityType: string, params?: QueryParams): APIRequest<PaginatedResponse<EntityFormType>> {
    const url = `/entities/${entityType}/drafts/`;
    const method = 'GET';

    return API.request<PaginatedResponse<EntityFormType>>({
      url,
      method,
      params,
    });
  }

  static createEntityFormsDrafts(entityType: string, form: string): APIRequest<EntityFormType> {
    const url = `/entities/${entityType}/drafts/`;
    const method = 'POST';

    return API.request<EntityFormType>({
      url,
      method,
      data: { form },
    });
  }

  static getPublicSubmission(entityType: string, publicUuid: string): APIRequest<EntitySubmissionDraftType> {
    const url = `/entities/${entityType}/public/${publicUuid}/`;
    const method = 'GET';

    return API.request<EntitySubmissionDraftType>({
      url,
      method,
    });
  }

  static getDraft(entityType: string, draftId: string): APIRequest<EntitySubmissionDraftType> {
    const url = `/entities/${entityType}/drafts/${draftId}/`;
    const method = 'GET';

    return API.request<EntitySubmissionDraftType>({
      url,
      method,
    });
  }

  static getPreview(
    entityType: string,
    isPublicSubmission: boolean,
    uuid: string,
    data?: {
      content: Record<string, AnswerTypes>;
      stack: string[];
    }
  ): APIRequest<EntitySubmissionDraftType> {
    const url = `/entities/${entityType}/${isPublicSubmission ? 'public' : 'drafts'}/${uuid}/submit/?preview`;
    const method = 'POST';

    return API.request<EntitySubmissionDraftType>({
      url,
      method,
      data,
    });
  }

  static getNextSection(
    entityType: string,
    isPublicSubmission: boolean,
    uuid: string,
    data: { stack: string[]; content?: Record<string, AnswerTypes> }
  ): APIRequest<EntitySubmissionDraftType> {
    const url = `/entities/${entityType}/${isPublicSubmission ? 'public' : 'drafts'}/${uuid}/?next`;
    const method = 'PATCH';

    return API.request<EntitySubmissionDraftType>({
      url,
      method,
      data,
    });
  }

  static getPreviousSection(
    entityType: string,
    isPublicSubmission: boolean,
    uuid: string,
    data: { stack: string[]; content?: Record<string, AnswerTypes> }
  ): APIRequest<EntitySubmissionDraftType> {
    const url = `/entities/${entityType}/${isPublicSubmission ? 'public' : 'drafts'}/${uuid}/`;
    const method = 'PATCH';

    return API.request<EntitySubmissionDraftType>({
      url,
      method,
      data,
    });
  }

  static updateEntityFormAutosave(
    entityType: string,
    isPublicSubmission: boolean,
    uuid: string,
    data: {
      content: Record<string, AnswerTypes>;
      stack: string[];
      publicSubmissionDetails?: PublicSubmissionUserDetails;
    }
  ): APIRequest<EntitySubmissionDraftType> {
    const url = `/entities/${entityType}/${isPublicSubmission ? 'public' : 'drafts'}/${uuid}/`;
    const method = 'PATCH';

    return API.request<EntitySubmissionDraftType>({
      url,
      method,
      data,
    });
  }

  static submitEntityForm(
    entityType: string,
    isPublicSubmission: boolean,
    uuid: string,
    data?: {
      content: Record<string, AnswerTypes>;
      stack: string[];
    }
  ): APIRequest<EntitySubmissionDraftType> {
    const url = `/entities/${entityType}/${isPublicSubmission ? 'public' : 'drafts'}/${uuid}/submit/`;
    const method = 'POST';

    return API.request<EntitySubmissionDraftType>({
      url,
      method,
      data,
    });
  }

  static updateEntityForm(
    entityType: string,
    formId: string,
    data: Partial<EntityFormType>
  ): APIRequest<EntityFormType> {
    const url = `/entities/${entityType}/forms/${formId}/`;
    const method = 'PATCH';

    return API.request<EntityFormType>({
      url,
      method,
      data,
    });
  }

  static loadEntitiesCount(entityType: string): APIRequest<EntityCountType> {
    const url = `/entities/${entityType}/count/`;
    const method = 'GET';

    return API.request<EntityCountType>({
      url,
      method,
    });
  }

  static loadEntitySubmissionActivities(
    entityType: string,
    entityId: string,
    params: PaginationParams
  ): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/entities/${entityType}/${entityId}/submission/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static loadDraftActivities(
    entityType: string,
    draftId: string,
    params: PaginationParams
  ): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/entities/${entityType}/drafts/${draftId}/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static addEntitySubmissionMessage(
    entityType: string,
    entityId: string,
    message: string,
    taggedUsers?: UserSearchResponse[]
  ): APIRequest<MessageActivityResponse> {
    const method = 'POST';
    const url = `/entities/${entityType}/${entityId}/submission/comments/`;

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message, taggedUsers },
    });
  }

  static addDraftMessage(
    entityType: string,
    draftId: string,
    message: string,
    taggedUsers?: UserSearchResponse[]
  ): APIRequest<MessageActivityResponse> {
    const method = 'POST';
    const url = `/entities/${entityType}/drafts/${draftId}/comments/`;

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message, taggedUsers },
    });
  }

  static uploadEntitySubmissionFile(
    entityType: string,
    entityId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<ActivityResponse> {
    const method = 'POST';
    const url = `/entities/${entityType}/${entityId}/submission/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static uploadDraftFile(
    entityType: string,
    draftId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<ActivityResponse> {
    const method = 'POST';
    const url = `/entities/${entityType}/drafts/${draftId}/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<ActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static removeEntitySubmissionMessage(
    entityType: string,
    entityId: string,
    messageId: string
  ): APIRequest<MessageActivityResponse> {
    const url = `/entities/${entityType}/${entityId}/submission/comments/${messageId}/`;
    const method = 'DELETE';

    return API.request<MessageActivityResponse>({
      url,
      method,
    });
  }

  static removeDraftMessage(
    entityType: string,
    draftId: string,
    messageId: string
  ): APIRequest<MessageActivityResponse> {
    const url = `/entities/${entityType}/drafts/${draftId}/comments/${messageId}/`;
    const method = 'DELETE';

    return API.request<MessageActivityResponse>({
      url,
      method,
    });
  }

  static removeEntitySubmissionFile(entityType: string, entityId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/entities/${entityType}/${entityId}/submission/files/${fileId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static removeDraftFile(entityType: string, draftId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/entities/${entityType}/drafts/${draftId}/files/${fileId}/`;

    return API.request<void>({
      url,
      method,
    });
  }

  static loadEntityActivities(
    entityType: string,
    entityId: string,
    params: PaginationParams
  ): APIRequest<VatixActivityListResponse> {
    const method = 'GET';
    const url = `/entities/${entityType}/${entityId}/activities/`;

    return API.request<VatixActivityListResponse>({
      url,
      method,
      params,
    });
  }

  static addEntityMessage(
    entityType: string,
    entityId: string,
    message: string,
    taggedUsers?: UserSearchResponse[]
  ): APIRequest<MessageActivityResponse> {
    const method = 'POST';
    const url = `/entities/${entityType}/${entityId}/comments/`;

    return API.request<MessageActivityResponse>({
      url,
      method,
      data: { message, taggedUsers },
    });
  }

  static uploadEntityFile(
    entityType: string,
    entityId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): APIRequest<VatixActivityResponse> {
    const method = 'POST';
    const url = `/entities/${entityType}/${entityId}/files/`;

    const data = new FormData();
    data.append('file', file);

    return API.request<VatixActivityResponse>({
      url,
      method,
      data,
      onUploadProgress,
      cancelTokenSource,
    });
  }

  static removeEntityFile(entityType: string, entityId: string, fileId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/entities/${entityType}/${entityId}/files/${fileId}`;

    return API.request<void>({
      url,
      method,
    });
  }

  static removeEntityMessage(entityType: string, entityId: string, messageId: string): APIRequest<void> {
    const method = 'DELETE';
    const url = `/entities/${entityType}/${entityId}/comments/${messageId}`;

    return API.request<void>({
      url,
      method,
    });
  }
}
