import React, { useState } from 'react';

import { observer } from 'mobx-react';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
  useDroppable,
  Modifier,
} from '@dnd-kit/core';

import AddIcon from '@mui/icons-material/Add';

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { Button } from 'vatix-ui/lib/components/Button';

import { EntityFieldRoles } from 'utils/api/types';

import { StagesOptionsProps, StageItemType } from './types';
import { ContainerLabel, OptionContainer, OptionHeader, StagesContainer } from './styles';
import DropdownSortableItem from '../DropdownOptions/components/DropDownSortableItem';

// TODO: backend is not ready to handle inactive options - uncomment when ready
// import InactiveItems from '../DropdownOptions/components/InactiveItems';

const CLOSED_CONTAINER_ID = 'closed-container';

const DroppableSecondContainer = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const { setNodeRef } = useDroppable({
    id: CLOSED_CONTAINER_ID,
  });

  return (
    <div ref={setNodeRef} data-droppable={CLOSED_CONTAINER_ID} style={{ position: 'relative', marginTop: '20px' }}>
      <ContainerLabel>Closed</ContainerLabel>
      <StagesContainer>{children}</StagesContainer>
    </div>
  );
};

const StagesOptions: React.FunctionComponent<StagesOptionsProps> = ({ options, setOptions }): React.ReactElement => {
  const optionsContainerRef = React.useRef<HTMLDivElement>(null);
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null);

  const activeOptions = options.filter((item) => item.active && !item.closed);

  // TODO: backend is not ready to handle inactive options - uncomment when ready
  // const inactiveOptions = options.filter((item) => !item.active);
  // const [displayInactiveOptions, setDisplayInactiveOptions] = useState(false);
  // const handleChangeDisplay = (): void => {
  //   setDisplayInactiveOptions((prev) => !prev);
  // };
  // const handleChangeToInactive = (key: string): void => {
  //   const newData = options.map((item) => {
  //     if (item.key === key) {
  //       return { ...item, active: true };
  //     }
  //     return item;
  //   });
  //   setOptions(newData);

  //   if (newData.filter((item) => !item.active).length === 0) {
  //     setDisplayInactiveOptions(false);
  //   }
  // };

  const handleAddNewOption = (): void => {
    const newOption: StageItemType = {
      active: true,
      closed: false,
      roles: [EntityFieldRoles.STAGE],
      key: `untitled${options.length + 1}`,
      value: `Untitled ${options.length + 1}`,
      displayKey: `untitled${options.length + 1}`,
    };

    setOptions([...options, newOption]);

    setTimeout(() => {
      scrollableContainerRef.current?.scrollTo({
        top: scrollableContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const [activeId, setActiveId] = useState<string | null>(null);
  const [secondContainerItem, setSecondContainerItem] = useState<StageItemType | null>(
    options.find((item) => item.closed) || null
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent): void => {
    setActiveId(event.active.id as string);
  };

  // This modifier restricts drag movement to stay within the container's boundaries
  // It prevents items from being dragged too far up or down beyond their logical positions
  const restrictToContainerModifier: Modifier = ({ transform, activeNodeRect }) => {
    // If we don't have access to the container or dragged item measurements, return unmodified transform
    if (!optionsContainerRef.current || !activeNodeRect) return transform;

    // Get the container's dimensions
    const containerRect = optionsContainerRef.current.getBoundingClientRect();

    // Find the current item's position in the list
    const activeItemKey = activeId as string;
    const currentIndex = activeOptions.findIndex((item) => item.key === activeItemKey);

    // Calculate boundaries:
    // bottomBoundary: prevents dragging below the container's bottom edge
    const bottomBoundary = containerRect.height - activeNodeRect.height - 100;
    // topBoundary: prevents dragging above where the first item should be
    // multiplied by currentIndex because items above should remain in their positions
    const topBoundary = -(activeNodeRect.height * currentIndex);

    // Return the transform with y-axis movement restricted between top and bottom boundaries
    return {
      ...transform,
      y: Math.max(Math.min(transform.y, bottomBoundary), topBoundary),
    };
  };

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!over) return;

    // check if option with key equal to over.id is closed
    const isOverClosed = options.find((item) => item.key === over.id)?.closed;

    if (over.id === CLOSED_CONTAINER_ID || isOverClosed) {
      const draggedItem = options.find((item) => item.key === active.id);

      if (draggedItem) {
        const newOptions = options.map((item) => {
          if (item.key === active.id) {
            return { ...item, closed: true };
          }
          if (item.closed) {
            return { ...item, closed: false };
          }
          return item;
        });
        setOptions(newOptions);
        setSecondContainerItem(draggedItem);
      }
    } else if (active.id !== over.id) {
      const oldIndex = options.findIndex((item) => item.key === active.id);
      const newIndex = options.findIndex((item) => item.key === over.id);
      const newData = arrayMove(options, oldIndex, newIndex);
      setOptions(newData);
    }
  };

  return (
    <>
      <OptionContainer ref={optionsContainerRef}>
        <OptionHeader>
          <p>Stages</p>
          {/* TODO: backend is not ready to handle inactive options - uncomment when ready 
           {inactiveOptions.length > 0 && (
            <Button variant="text" color="primary" size="medium" onClick={handleChangeDisplay}>
              {displayInactiveOptions ? 'Show active options' : 'Show inactive options'}
            </Button>
          )} */}
        </OptionHeader>
        {/* TODO: backend is not ready to handle inactive options - uncomment when ready 
         {displayInactiveOptions ? (
          <InactiveItems inactiveOptions={inactiveOptions} handleActiveChange={handleChangeToInactive} />
        ) : ( */}
        <div style={{ position: 'relative', marginTop: '20px' }}>
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToContainerModifier]}
          >
            <>
              <ContainerLabel>Open</ContainerLabel>

              <StagesContainer>
                <SortableContext items={activeOptions.map((item) => item.key)} strategy={verticalListSortingStrategy}>
                  <div
                    ref={scrollableContainerRef}
                    style={{
                      maxHeight: '220px',
                      overflow: 'auto',
                      position: 'relative',
                    }}
                  >
                    {activeOptions.length > 0 ? (
                      activeOptions.map((item) => (
                        <DropdownSortableItem
                          id={item.key}
                          key={item.key}
                          editView
                          item={item}
                          options={options}
                          setOptions={setOptions}
                          disableDeactivate
                        />
                      ))
                    ) : (
                      <p>No stages added yet. Click &apos;Add stage&apos; to create the first stage.</p>
                    )}
                  </div>
                </SortableContext>
                {/* {!displayInactiveOptions && ( */}
                <Button startIcon={<AddIcon />} value="text" onClick={handleAddNewOption} color="primary" size="small">
                  Add option
                </Button>
                {/* )} */}
              </StagesContainer>

              <DroppableSecondContainer>
                <DropdownSortableItem
                  id={secondContainerItem?.key || ''}
                  key={secondContainerItem?.key || ''}
                  editView
                  item={secondContainerItem as StageItemType}
                  options={options}
                  setOptions={setOptions}
                  disabled
                  disableDeactivate
                />
              </DroppableSecondContainer>
            </>

            <DragOverlay>
              {activeId ? (
                <DropdownSortableItem
                  id={activeId}
                  editView
                  item={options.find((item) => item.key === activeId)!}
                  options={options}
                  setOptions={setOptions}
                  disableDeactivate
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
        {/* )} */}
      </OptionContainer>
    </>
  );
};

export default observer(StagesOptions);
