import styled from 'styled-components';

export const Container = styled.div`
  gap: 20px;
  display: flex;
  padding: 20px;
  background: #fff;
  flex-direction: column;
  border-radius: 0px 0px 8px 8px;
`;

export const Header = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 160%;
  font-style: normal;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const InputWrapper = styled.div`
  flex: 1;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: rgba(0, 0, 0, 0.87);
`;
