import { action, observable, runInAction, makeObservable, reaction } from 'mobx';
import { eventBus } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import { cloneDeep, isEqual } from 'lodash';

import API from 'utils/api';
import { isNotFound } from 'utils/api/errors';

import { EntityFieldType, FormBuilderType, FormToFieldMappingResponse, ProtectorType } from 'utils/api/types';

import FormBuilder from 'stores/FormBuilder';

import RootStore from '../../Root';
import { NEW_FORM_ID } from '../FormBuilder';

export default class FormMappingStore {
  @observable error?: AxiosError;

  @observable rules: FormToFieldMappingResponse = [];

  @observable fields?: EntityFieldType[];

  @observable isLoaded = false;

  @observable isLoading = false;

  @observable mappingModified = false;

  private initialRules: FormToFieldMappingResponse = [];

  rootStore: RootStore;

  api: typeof API;

  constructor(rootStore: RootStore, api: typeof API, private formBuilder: FormBuilder) {
    this.rootStore = rootStore;
    this.api = api;

    makeObservable(this);

    reaction(
      () => this.rules,
      () => {
        if (this.isLoaded) {
          this.mappingModified = !isEqual(this.rules, this.initialRules);
        }
      }
    );

    eventBus.register(this);
  }

  getFormData(): FormBuilderType | undefined {
    if (!this.formBuilder.data) {
      throw new Error('Form data is not loaded');
    }
    return this.formBuilder.data;
  }

  async saveMapping(entityType: string, formId: string): Promise<void> {
    const questions = this.formBuilder.data?.order.flatMap(
      (section) => this.formBuilder.data?.properties[section].order
    );
    if (!questions) return;

    const rules: FormToFieldMappingResponse = questions.map((question) => ({
      question: question || '',
      field: this.rules.find((r) => r.question === question)?.field || null,
    }));

    const filteredRules = rules.filter(({ field }) => field !== null);

    try {
      await this.api.saveFormToFieldMapping(entityType, formId, filteredRules)();
      runInAction(() => {
        this.initialRules = cloneDeep(filteredRules);
        this.mappingModified = false;
      });
    } catch (error) {
      Logger.error('Failed to save form to field mapping', { error });
      this.rootStore.notification.enqueueErrorSnackbar(
        // eslint-disable-next-line max-len
        'The form layout and settings have been published, but there was an issue updating the field mapping. Please review the field mapping and try again.'
      );
    }
  }

  @action.bound
  async loadDetails(entityType: string, formId: string): Promise<void> {
    if (this.isLoaded && this.formBuilder.formId === formId) {
      return;
    }

    this.isLoaded = false;
    this.isLoading = true;

    // if the form is new, we don't need to load the mapping
    if (formId === NEW_FORM_ID) {
      try {
        const fieldsResponse = await this.api.loadEntityFields(entityType, { limit: 1000, offset: 0 })();

        this.error = undefined;
        this.fields = fieldsResponse.data.results;
      } catch (error) {
        const axiosError = error as AxiosError;
        this.error = axiosError;
        Logger.error('Failed to load entity fields', { error: axiosError });
        this.rootStore.notification.enqueueErrorSnackbar('Failed to load field mapping');
      } finally {
        this.isLoaded = true;
        this.isLoading = false;
      }
      return;
    }

    try {
      const [mappingResponse, fieldsResponse] = await Promise.all([
        this.api.loadFormToFieldMapping(entityType, formId)(),
        this.api.loadEntityFields(entityType, { limit: 1000, offset: 0 })(),
      ]);

      runInAction(() => {
        this.error = undefined;
        this.rules = mappingResponse.data;
        this.fields = fieldsResponse.data.results;
        this.initialRules = cloneDeep(mappingResponse.data);
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      this.error = axiosError;

      if (!isNotFound(axiosError)) {
        Logger.error(`Failed to load mapping for form ${formId}`, { error: axiosError, entityType });
        this.rootStore.notification.enqueueErrorSnackbar('Failed to load field mapping');
      }
    } finally {
      this.isLoaded = true;
      this.isLoading = false;
    }
  }

  getFieldForQuestion(question: string): EntityFieldType | null {
    if (!this.rules || !this.fields) return null;
    const mapping = this.rules.find((rule) => rule.question === question);

    if (!mapping) return null;

    return this.fields.find((field) => field.key === mapping.field) || null;
  }

  @action
  updateFieldMapping(question: string, field: string | null): void {
    // if the field is null, we need to remove the rule
    if (!field) {
      this.rules = [...this.rules.filter((rule) => rule.question !== question)];
      return;
    }

    const index = this.rules.findIndex((rule) => rule.question === question);
    if (index !== -1) {
      this.rules = [
        ...this.rules.slice(0, index),
        { ...this.rules[index], field },
        ...this.rules.slice(index + 1)
      ];
    } else {
      this.rules = [...this.rules, { question, field }];
    }
  }

  getFieldsByType(
    type: ProtectorType
  ): {
    compatibleFields: EntityFieldType[];
    availableFields: EntityFieldType[];
  } {
    if (!this.fields) return { compatibleFields: [], availableFields: [] };

    // do not show fields that are not editable (e.g. id for events)
    const compatibleFields = this.fields.filter(({ protectorType, editable }) => protectorType === type && editable);
    const mappedFieldKeys = new Set(this.rules?.map(({ field }) => field).filter(Boolean));

    const availableFields = compatibleFields.filter(({ key }) => !mappedFieldKeys.has(key));

    return {
      compatibleFields,
      availableFields,
    };
  }

  @action.bound
  resetMapping(): void {
    this.rules = [];
    this.isLoaded = false;
    this.isLoading = false;
    this.error = undefined;
    this.fields = undefined;
  }
}
