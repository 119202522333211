import React from 'react';

import GridLookupRenderer from 'components/Grid/renderers/GridLookupRenderer';

import { ColumnDefinition } from 'components/Grid/types';
import { LinksType } from 'utils/api/types';
import { EntityModules } from 'core/constants';
import { EmptyText } from 'components/Grid/renderers/GridListRenderer/styles';
import { getEntityTranslation } from 'stores/EntityDetails/utils';

const getEntityGridColumn = (
  entityModule: EntityModules,
  disableFiltering = false,
  disableSorting = false
): ColumnDefinition =>
  (({
    defaultVisibility: false,
    field: `links_${entityModule}`,
    nativeFilter: getEntityTranslation[entityModule].singular.toLowerCase(),
    filterable: !disableFiltering,
    sortable: !disableSorting,
    headerName: getEntityTranslation[entityModule].singular,
    renderCell: ({ value }: { value: Array<LinksType> | null }) =>
      value && value[0] ? (
        <GridLookupRenderer entity={entityModule} instance={value[0].instance} />
      ) : (
        <EmptyText>N/A</EmptyText>
      ),
    valueGetter: ({
      value,
      row,
    }: {
      value: { uuid: string; name: string } | null;
      row: { links: Array<LinksType> };
    }) => {
      if (!row.links) return [];
      return value !== null
        ? row.links.filter((link) => link.entity === entityModule)
        : row.links.find((link) => link.entity === entityModule);
    },
  } as unknown) as ColumnDefinition);

export const getEntityGridColumns = (
  activeEntityModules: EntityModules[],
  disableFiltering = false,
  disableSorting = false
): ColumnDefinition[] =>
  activeEntityModules.map((module) => getEntityGridColumn(module, disableFiltering, disableSorting));

export default getEntityGridColumn;
