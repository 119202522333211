import { EntityFormType } from 'utils/api/types';

export enum ModalState {
  DRAFT_EXISTS = 'DRAFT_EXISTS',
  MULTIPLE_FORMS = 'MULTIPLE_FORMS',
  SINGLE_FORM = 'SINGLE_FORM',
  NO_FORMS = 'NO_FORMS',
}

export interface CreateEventModalProps {
  onClose: (created: boolean) => void;
  openModal: boolean;
  forms: EntityFormType[];
  drafts: EntityFormType[];
}
