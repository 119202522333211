/* eslint-disable max-len */
export const defaultTemplate = `Thank you for submitting the form! 

We have received your submission and will review it shortly. If further action or follow-up is needed, our team will contact you.

Best regards,
{{OrganisationName}}`;

export enum FormAudience {
  PUBLIC = 'public',
  USER = 'user',
  ALL = 'all',
}

export const DEFAULT_SETTINGS: FormSettings = {
  isActive: true,
  audience: FormAudience.USER,
  notifications: {
    teamManagers: true,
    specificUsersActive: false,
    specificUsers: [],
  },
  confirmationEmail: {
    isActive: false,
    sender: '',
    subject: '',
    replyTo: '',
    template: defaultTemplate,
  },
  publicSubmission: {
    publicSubmissionType: 'Anonymous',
    options: {
      name: false,
      email: false,
      phone: false,
    },
  },
  publicUuid: '',
};

type ConfirmationEmail = {
  isActive: boolean;
  sender?: string;
  subject?: string;
  replyTo?: string;
  template?: string;
};

export type PublicSubmissionOptions = {
  name: boolean;
  email: boolean;
  phone: boolean;
};

export type FormSettings = {
  isActive: boolean;
  audience: FormAudience;
  notifications: {
    teamManagers: boolean;
    specificUsersActive: boolean;
    specificUsers: { uuid: string }[];
  };
  confirmationEmail: ConfirmationEmail;
  publicSubmission: {
    publicSubmissionType: 'Anonymous' | 'Named';
    options: PublicSubmissionOptions;
  };
  publicUuid: string;
};

export type FormSettingsResponse = {
  isActive: boolean;
  audience: FormAudience[];
  notifications: {
    teamManagers: boolean;
    specificUsers: { uuid: string; name?: string }[];
  };
  confirmationEmail: ConfirmationEmail;
  publicSubmission: PublicSubmissionOptions;
  publicUuid: string;
};
