import React from 'react';

import { reverse } from 'named-urls';

import { Link } from 'react-router-dom';

import routes from 'core/routes';

import { NotificationTarget } from 'core/constants';

import { GoToLink, Text } from './styles';
import { NotificationProps } from './types';

const Notification: React.FunctionComponent<NotificationProps> = ({ notification }) => {
  switch (notification.target) {
    case NotificationTarget.NEW_INCIDENT_MESSAGE:
    case NotificationTarget.NEW_INCIDENT_SUBMISSION_MESSAGE:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' mentioned you in the incident: '}
          <GoToLink
            to={reverse(routes.dashboard.incidents.details, { incidentId: notification.instance.uuid })}
            as={Link}
          >
            {notification.instance.subject}
          </GoToLink>
        </Text>
      );
    case NotificationTarget.NEW_TASK_MESSAGE:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' mentioned you in the action: '}
          <GoToLink to={reverse(routes.dashboard.tasks.details, { taskId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );
    case NotificationTarget.NEW_TASK_ASSIGNED_TO_ME:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' assigned you an action: '}
          <GoToLink to={reverse(routes.dashboard.tasks.details, { taskId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );
    case NotificationTarget.NEW_INCIDENT_REPORTED:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' reported a new incident: '}
          <GoToLink
            to={reverse(routes.dashboard.incidents.details, { incidentId: notification.instance.uuid })}
            as={Link}
          >
            {notification.instance.subject}
          </GoToLink>
        </Text>
      );
    case NotificationTarget.NEW_INCIDENT_ASSIGNED_TO_ME:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' assigned you an incident: '}
          <GoToLink
            to={reverse(routes.dashboard.incidents.details, { incidentId: notification.instance.uuid })}
            as={Link}
          >
            {notification.instance.subject}
          </GoToLink>
        </Text>
      );
    case NotificationTarget.NEW_FORM_SUBMITTED:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' submitted a new form: '}
          <GoToLink
            to={reverse(routes.dashboard.audits.forms.details, { formId: notification.instance.uuid })}
            as={Link}
          >
            {notification.instance.name}
          </GoToLink>
        </Text>
      );

    case NotificationTarget.NEW_EVENT_REPORTED:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' submitted a new event: '}
          <GoToLink to={reverse(routes.dashboard.events.details, { entityId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );

    case NotificationTarget.NEW_EVENT_ASSIGNED_TO_ME:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' assigned you an event: '}
          <GoToLink to={reverse(routes.dashboard.events.details, { entityId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );

    case NotificationTarget.NEW_EVENT_MESSAGE:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' mentioned you in the event: '}
          <GoToLink to={reverse(routes.dashboard.events.details, { entityId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );

    case NotificationTarget.NEW_ASSET_MESSAGE:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' mentioned you in the asset: '}
          <GoToLink to={reverse(routes.dashboard.assets.details, { entityId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );

    case NotificationTarget.NEW_SITE_MESSAGE:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' mentioned you in the site: '}
          <GoToLink to={reverse(routes.dashboard.sites.details, { entityId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );

    case NotificationTarget.NEW_SITE_ASSIGNED_TO_ME:
      return (
        <Text>
          {notification.dismissed ? notification.creator.name : <b>{notification.creator.name}</b>}
          {' assigned you an site: '}
          <GoToLink to={reverse(routes.dashboard.sites.details, { entityId: notification.instance.uuid })} as={Link}>
            {notification.instance.name}
          </GoToLink>
        </Text>
      );
    default:
      return (
        <Text>
          <b>{notification.creator.name}</b>
          {' Unknown notification'}
        </Text>
      );
  }
};

export default Notification;
