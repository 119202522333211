import { action, makeObservable } from 'mobx';
import { Active, Over, UniqueIdentifier } from '@dnd-kit/core';

import { ProtectorType } from 'utils/api/types';

import FormBuilder from '../FormBuilder';

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

class FormBuilderDragAndDrop {
  constructor(private formBuilder: FormBuilder) {
    makeObservable(this);
  }

  @action.bound
  setItems(items: Items): void {
    this.formBuilder.items = items;
  }

  @action.bound
  moveItemsBetweenContainers(
    activeContainer: string,
    overContainer: string,
    overId: string,
    active: Active,
    over: Over
  ): void {
    const { items, data } = this.formBuilder;

    if (!items[activeContainer] || !items[overContainer]) return;

    const activeItems = items[activeContainer];
    const overItems = items[overContainer];
    const activeIndex = activeItems.indexOf(active.id);

    if (activeIndex === -1 || !active.rect.current.translated) return;

    const newIndex =
      overId in items
        ? overItems.length
        : overItems.indexOf(overId) + (active.rect.current.translated.top > over.rect.top + over.rect.height ? 1 : 0);

    // Move items between containers
    this.formBuilder.items = {
      ...items,
      [activeContainer]: activeItems.filter((item) => item !== active.id),
      [overContainer]: [...overItems.slice(0, newIndex), activeItems[activeIndex], ...overItems.slice(newIndex)],
    };

    if (!data) return;

    const activeContainerProperties = data.properties?.[activeContainer];
    const overContainerProperties = data.properties?.[overContainer];

    if (!activeContainerProperties || !overContainerProperties) return;

    activeContainerProperties.order = activeContainerProperties.order.filter((item) => item !== active.id);
    overContainerProperties.order = [
      ...overContainerProperties.order.slice(0, newIndex),
      active.id as string,
      ...overContainerProperties.order.slice(newIndex),
    ];

    const movedItem = activeContainerProperties.properties[active.id];
    if (this.formBuilder.data === undefined) return;
    this.formBuilder.data.properties[overContainer].properties[active.id] = movedItem;
    delete this.formBuilder.data.properties[activeContainer].properties[active.id];

    // if the moved question was of type multiChoice or singleChoice, update the actions
    if (movedItem.questionType === ProtectorType.MultiChoice || movedItem.questionType === ProtectorType.SingleChoice) {
      this.formBuilder.formAction.moveQuestionActions(activeContainer, overContainer, active.id as string);
    }

    // if the moved question was required, make the new question required
    if (this.formBuilder.data?.properties[activeContainer].required.includes(active.id as string)) {
      // add field to required in new container
      this.formBuilder.questionLogic.makeQuestionRequired(overContainer, active.id as string);

      // remove field form required from previous container
      this.formBuilder.questionLogic.makeQuestionRequired(activeContainer, active.id as string);
    }
  }

  @action.bound
  modifyItemsInContainer(container: string, items: string[]): void {
    // Update the items in the specified container
    this.formBuilder.items = {
      ...this.formBuilder.items,
      [container]: items,
    };
    if (this.formBuilder.data) {
      this.formBuilder.data.properties[container].order = items;
    }
  }

  @action.bound
  findItem = (id: string): string | undefined => {
    if (id in this.formBuilder.items) {
      return id;
    }
    return Object.keys(this.formBuilder.items).find((key) => this.formBuilder.items[key].includes(id));
  };
}

export default FormBuilderDragAndDrop;
