import { action, makeObservable, runInAction } from 'mobx';

import { AxiosError } from 'axios';

import { reverse } from 'named-urls';

import _ from 'lodash';

import RootStore from 'stores/Root';

import API from 'utils/api';

import { EntityFormType, EntityPropertiesType, FormBuilderType } from 'utils/api/types';

import routes from 'core/routes';

import { Items } from 'containers/LayoutEditor/components/Layout/types';

import FormBuilder, { NEW_FORM_ID } from '../FormBuilder';

class FormBuilderDataManager {
  store: RootStore;

  api: typeof API;

  constructor(rootStore: RootStore, api: typeof API, private formBuilder: FormBuilder) {
    this.store = rootStore;
    this.api = api;
    makeObservable(this);
  }

  @action.bound
  async loadLayout(moduleName: string, formId: string): Promise<void> {
    this.formBuilder.isLoaded = false;
    this.formBuilder.formId = formId;
    // if the formId is new, we create a new layout
    if (formId === NEW_FORM_ID) {
      this.formBuilder.data = {
        order: [],
        properties: {},
      };
      this.formBuilder.loadedData = {
        order: [],
        properties: {},
      };
      this.formBuilder.lastPublished = undefined;
      this.transformDataForDisplay();
      this.formBuilder.formName = '';
      this.formBuilder.initialName = '';
      this.formBuilder.isLoaded = true;

      // load form mapping details
      this.formBuilder.formMapping.loadDetails(moduleName, formId);
      return;
    }
    try {
      const {
        data: { form, name, updatedAt, settings },
      } = await this.api.loadEntityForm(moduleName, formId)();
      this.formBuilder.formName = name;
      this.formBuilder.initialName = name;
      this.formBuilder.data = (form as unknown) as FormBuilderType;
      this.formBuilder.loadedData = (form as unknown) as FormBuilderType;
      this.formBuilder.lastPublished = updatedAt;
      this.formBuilder.containers = form.order;
      this.formBuilder.formBuilderSettings.loadedSettings(settings);
      this.transformDataForDisplay();

      // save form actions
      form.order.forEach((sectionKey) => {
        const section = form.properties[sectionKey];
        if (section.actions.length > 0) {
          runInAction(() => {
            this.formBuilder.formAction.formActions = {
              ...this.formBuilder.formAction.formActions,
              [sectionKey]: section.actions,
            };
          });
        }
      });

      this.formBuilder.loadedActions = _.cloneDeep(this.formBuilder.formAction.formActions);

      // load form mapping details
      this.formBuilder.formMapping.loadDetails(moduleName, formId);

      const fieldsIds = Object.values(this.formBuilder.items).flat();
      this.store.entityFields.changeFieldsToDisabled(fieldsIds as string[]);
      this.formBuilder.error = undefined;
    } catch (err) {
      const e = err as AxiosError;
      this.formBuilder.error = e;
    } finally {
      this.formBuilder.isLoaded = true;
    }
  }

  // SAVING
  @action.bound
  async saveForm(): Promise<void> {
    // Validate form structure
    if (!this.formBuilder.validation.validateEmptyForm()) {
      return;
    }

    if (!this.formBuilder.validation.validateEmptySections()) {
      return;
    }

    if (!this.formBuilder.validation.validateSectionUniqueness()) {
      return;
    }

    if (!this.formBuilder.validation.validateFormName()) {
      return;
    }

    const transformed = this.transformDataForSave();

    if (!this.formBuilder.validation.validateQuestionsUniqueness(transformed)) {
      return;
    }

    if (!this.formBuilder.formBuilderSettings.validateSettings()) {
      return;
    }

    const formSettings = this.formBuilder.formBuilderSettings.formSavedSettings();
    const formData = {
      name: this.formBuilder.formName,
      form: transformed,
      settings: formSettings,
    };

    try {
      const response =
        this.formBuilder.formId === NEW_FORM_ID
          ? await this.api.createEntityForm('events', formData)()
          : await this.api.updateEntityForm('events', this.formBuilder.formId, formData)();

      // after saving data for form, we need to save the form mapping
      await this.formBuilder.formMapping.saveMapping('events', response.data.uuid);

      const successMessage =
        this.formBuilder.formId === NEW_FORM_ID ? 'Form created successfully' : 'Form published successfully';

      this.store.notification.enqueueSuccessSnackbar(successMessage);

      // Additional steps for new form
      if (this.formBuilder.formId === NEW_FORM_ID) {
        this.formBuilder.formId = response.data.uuid;
        this.store.routing.push(
          reverse(routes.dashboard.objectManager.details.formBuilder.details, {
            moduleName: 'events',
            formId: response.data.uuid,
          })
        );
      }

      // Update form builder state with response data
      this.updateFormBuilderState(response.data);
    } catch (err) {
      const e = err as AxiosError;
      this.formBuilder.error = e;
      const errorMessage =
        e.response?.data.message || `Could not ${this.formBuilder.formId === NEW_FORM_ID ? 'create' : 'save'} form`;
      this.store.notification.enqueueErrorSnackbar(errorMessage);
    }
  }

  // Helper method to update form builder state after successful save
  private updateFormBuilderState(responseData: EntityFormType): void {
    this.formBuilder.formId = responseData.uuid;
    this.formBuilder.lastPublished = responseData.updatedAt;
    this.formBuilder.initialName = this.formBuilder.formName;
    this.formBuilder.loadedData = _.cloneDeep(responseData.form);
    this.formBuilder.data = _.cloneDeep(responseData.form);
    this.formBuilder.formBuilderSettings.settingsWasEdited = false;
    this.formBuilder.formBuilderSettings.loadedSettings(responseData.settings);
    this.formBuilder.loadedActions = _.cloneDeep(this.formBuilder.formAction.formActions);

    this.formBuilder.contentWasEdited = false;
    this.formBuilder.contentOfLayoutWasEdited = false;
    this.transformDataForDisplay();
  }

  @action.bound
  transformDataForSave(): FormBuilderType {
    const result: {
      order: string[];
      properties: EntityPropertiesType;
    } = {
      order: [],
      properties: {},
    };

    const updatedContainers = this.formBuilder.containers.map((containerKey) => {
      if (!this.formBuilder.items[containerKey]) return containerKey;

      const { title } = this.formBuilder.sectionLogic.getSectionTitleAndDescription(containerKey);

      _.set(result.properties, containerKey, {
        order: this.formBuilder.items[containerKey],
        title,
        actions: this.formBuilder.formAction.formActions[containerKey],
        description: this.formBuilder.data?.properties[containerKey]?.description,
        required: this.formBuilder.data?.properties[containerKey].required,
        properties: this.formBuilder.data?.properties[containerKey].properties,
      });

      return containerKey;
    });

    result.order = updatedContainers;

    return (result as unknown) as FormBuilderType;
  }

  @action.bound
  transformDataForDisplay(): void {
    if (!this.formBuilder.data) return;
    const result: Items = {};

    this.formBuilder.data.order.forEach((key: string) => {
      result[key] = this.formBuilder.data!.properties[key].order;
    });
    this.formBuilder.items = result;
  }
}

export default FormBuilderDataManager;
