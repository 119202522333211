import styled from 'styled-components';

export const OptionContainer = styled.div`
  border: 1px solid #ccc;
  background: #fcfcfc;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 8px 0;
`;

export const OptionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 4px;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0;
  }
`;

export const ContainerLabel = styled.div`
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 8px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 12px;
`;

export const StagesContainer = styled.div`
  padding: 16px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
`;
