import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import { useRouteMatch } from 'react-router-dom';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { useStore } from 'utils/hooks/store';

import { useLocalStorage } from 'utils/hooks/localStorage';

import SubmissionSection from 'containers/EntitiesFormSubmission/components/SubmissionSection';

import FormPreview from 'containers/EntitiesFormSubmission/components/FormPreview/FormPreview';

import SuccessPage from 'components/SuccessPage/SuccessPage';

import {
  Overview,
  Container,
  HeaderTitle,
  MainContainer,
  ButtonWrapper,
  HeaderWrapper,
  LogoContainer,
  SectionContainer,
  SubmissionContainer,
} from './styles';

import logo from '../../assets/images/VatixLogoPublicSubmission.png';
import UserDetailsStep from './components/UserDetailsStep';
import CancelFlowModal from './components/CancelFlowModal';

const PUBLIC_SUBMISSION_LOCAL_STORAGE_KEY = 'public_submission_user_details_';

const EntitiesFormPublicSubmission: React.FC = (): React.ReactElement => {
  const match = useRouteMatch();
  const {
    notification,
    entitiesFormSubmission: {
      data,
      isError,
      updating,
      isLoading,
      showPreview,
      onNextClick,
      onPreviousClick,
      requiredNotFilled,
      displayReloadPage,
      displaySuccessPage,
      loadPublicSubmission,
      setDisplayReloadPage,
      displayUserDetailsStep,
      getCurrentSectionProperties,
      publicSubmissionUserDetails,
      checkIfAllRequiredFieldsAreFilled,
      moveToFirstSectionFromUserDetails,
    },
  } = useStore();
  const { formId } = match.params as { formId: string };

  // Use localStorage values if they exist, otherwise use empty strings
  const [storedDetails, setStoredDetails, removeStoredDetails] = useLocalStorage(
    `${PUBLIC_SUBMISSION_LOCAL_STORAGE_KEY}${formId}`,
    {
      name: '',
      email: '',
      phone: '',
      rememberUserDetails: false,
    }
  );

  // Determine initial values from either localStorage or store
  const userDetails = {
    name: storedDetails.name || publicSubmissionUserDetails?.name || null,
    email: storedDetails.email || publicSubmissionUserDetails?.email || null,
    phone: storedDetails.phone || publicSubmissionUserDetails?.phone || null,
    rememberUserDetails: storedDetails.rememberUserDetails || false,
  };

  const [rememberUserDetails, setRememberUserDetails] = useState(userDetails.rememberUserDetails);
  const [openCancelFlowModal, setOpenCancelFlowModal] = useState(false);

  const onCancel = (): void => {
    if (data?.stack.length === 1) {
      setOpenCancelFlowModal(true);
    } else {
      onPreviousClick();
    }
  };

  const onClickNext = (): void => {
    // handle the first step
    if (displayUserDetailsStep) {
      // check if all required fields are filled
      if (!checkIfAllRequiredFieldsAreFilled()) {
        notification.enqueueErrorSnackbar('Please fill in all required fields');
        return;
      }

      if (rememberUserDetails) {
        setStoredDetails({
          name: publicSubmissionUserDetails?.name || '',
          email: publicSubmissionUserDetails?.email || '',
          phone: publicSubmissionUserDetails?.phone || '',
          rememberUserDetails: true,
        });
      } else {
        removeStoredDetails();
      }
      moveToFirstSectionFromUserDetails();
    } else {
      onNextClick();
    }
  };

  React.useEffect(() => {
    loadPublicSubmission(formId);
  }, [formId]);

  if (isError) {
    return (
      <MainContainer>
        <DataError />
      </MainContainer>
    );
  }

  if (isLoading || updating || !data) {
    return (
      <MainContainer>
        <CenteredCircularProgress key="loading-progress" />
      </MainContainer>
    );
  }

  const currentSectionProperties = getCurrentSectionProperties();

  if (!currentSectionProperties) {
    return <DataError />;
  }

  if (displaySuccessPage) {
    return <SuccessPage message="Event reported successfully!" />;
  }

  if (displayReloadPage) {
    return <SuccessPage message="Event form submission cancelled. Refresh the page to start a new submission." />;
  }

  if (showPreview) {
    return (
      <MainContainer>
        <LogoContainer>
          <img alt="Vatix logo" src={logo} />
        </LogoContainer>
        <HeaderWrapper>
          <HeaderTitle>New Submission</HeaderTitle>
        </HeaderWrapper>
        <Overview>
          <FormPreview />
        </Overview>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <LogoContainer>
        <img alt="Vatix logo" src={logo} />
      </LogoContainer>
      <HeaderWrapper>
        <HeaderTitle>New Submission</HeaderTitle>
      </HeaderWrapper>
      <Overview>
        <SubmissionContainer>
          {displayUserDetailsStep ? (
            <UserDetailsStep
              initialState={userDetails}
              rememberUserDetails={rememberUserDetails}
              onRemember={(value) => setRememberUserDetails(value)}
            />
          ) : (
            <SectionContainer>
              <SubmissionSection section={currentSectionProperties} />
            </SectionContainer>
          )}
          <Container>
            <ButtonWrapper>
              <Button
                size="large"
                id="cancelButton"
                variant="outlined"
                onClick={onCancel}
                fullWidth={window.innerWidth <= 768}
              >
                {data.stack.length === 1 ? 'Cancel' : 'Back'}
              </Button>
              <Button
                size="large"
                id="submitButton"
                variant="contained"
                onClick={onClickNext}
                fullWidth={window.innerWidth <= 768}
                disabled={updating || requiredNotFilled.length > 0}
              >
                {data.hasNext ? 'Next' : 'Submit'}
              </Button>
            </ButtonWrapper>
          </Container>
        </SubmissionContainer>
      </Overview>
      <CancelFlowModal
        open={openCancelFlowModal}
        onClose={() => setOpenCancelFlowModal(false)}
        onAction={() => setDisplayReloadPage(true)}
      />
    </MainContainer>
  );
};

export default observer(EntitiesFormPublicSubmission);
