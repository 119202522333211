import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';

const StyledModal = styled(Dialog)`
  .MuiPaper-root {
    padding: 24px;
    border-radius: 8px;
    border: 0px solid #eee;
    background: #fff;
    box-shadow: 0px 8px 50px 0px rgba(7, 5, 26, 0.07);
  }
`;

export const SimpleModal = styled(StyledModal)`
  .MuiPaper-root {
    max-width: 360px;
    align-items: center;
  }
`;

export const StyledModalTitle = styled.h2`
  font-size: 20px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 12px 0 0;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
`;
