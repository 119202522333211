/* eslint-disable max-len */
import React from 'react';

import { Switch } from '@mui/material';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { FormAudience } from 'stores/FormBuilderSettings/types';

import CustomMultipleUserDropdownField from 'containers/IncidentDetails/components/CustomMultipleUserPicker/CustomMultipleUserDropdownField';

import { PermissionsColumn, PermissionsContainer } from './styles';
import { Row, SettingsMainContainer } from '../SectionMainContainer/SectionMainContainer';

const PermissionsNotifications: React.FC = () => {
  const {
    formBuilder: { formBuilderSettings },
  } = useStore();

  const updateNotificationsSettings = (updates: Partial<typeof formBuilderSettings.settings.notifications>): void => {
    formBuilderSettings.setSettings({
      ...formBuilderSettings.settings,
      notifications: {
        ...formBuilderSettings.settings.notifications,
        ...updates,
      },
    });
  };

  return (
    <SettingsMainContainer
      title="Permissions & Notifications"
      description={formBuilderSettings.getPermissionsNotificationsSubheading()}
    >
      {formBuilderSettings.settings.audience !== FormAudience.PUBLIC && (
        <PermissionsContainer>
          <Switch
            checked={formBuilderSettings.settings.notifications.teamManagers}
            size="small"
            color="primary"
            onClick={() =>
              updateNotificationsSettings({ teamManagers: !formBuilderSettings.settings.notifications.teamManagers })
            }
          />
          <PermissionsColumn>
            <h1>Notify team managers</h1>
            <p>
              Automatically notify and grant access to managers of the teams the reporter belongs to. This ensures
              relevant managers are informed and can access the event records.
            </p>
          </PermissionsColumn>
        </PermissionsContainer>
      )}

      <PermissionsContainer>
        <Switch
          checked={formBuilderSettings.settings.notifications.specificUsersActive}
          size="small"
          color="primary"
          onClick={() =>
            updateNotificationsSettings({
              specificUsersActive: !formBuilderSettings.settings.notifications.specificUsersActive,
            })
          }
        />
        <PermissionsColumn>
          <h1>Notify specific users</h1>
          <p>Add individual users to notify and grant access when this form is submitted.</p>

          {formBuilderSettings.settings.notifications.specificUsersActive && (
            <Row title="Add specific users">
              <CustomMultipleUserDropdownField
                description="Specific users"
                value={formBuilderSettings.settings.notifications.specificUsersActive}
                onChange={(value) =>
                  updateNotificationsSettings({
                    specificUsers:
                      (value as string[]).length > 0 ? (value as string[]).map((user) => ({ uuid: user })) : [],
                  })
                }
              />
            </Row>
          )}
        </PermissionsColumn>
      </PermissionsContainer>
    </SettingsMainContainer>
  );
};

export default observer(PermissionsNotifications);
