import React from 'react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { observer } from 'mobx-react';

import Grid from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import { DisplayProtectorType, EntityFieldRoles, EntityFieldType, ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import { EntityModules } from 'core/constants';

import ActionCell from '../ActionCell';
import CreateEditFieldModal from '../CreateEditFieldModal';

const FieldTypeCell = ({ row, colDef }: { row: EntityFieldType; colDef: ColumnDefinition }): React.ReactElement => {
  if (row.properties?.roles?.includes(EntityFieldRoles.STAGE)) {
    return <p>Stage</p>;
  }
  const protectorType = row[colDef.field as keyof EntityFieldType];
  if (protectorType === ProtectorType.Lookup) {
    const { plural } = getEntityTranslation[row.properties?.lookupType as EntityModules];
    return (
      <p>
        {DisplayProtectorType[protectorType]} ({plural})
      </p>
    );
  }
  return <p>{DisplayProtectorType[protectorType as ProtectorType]}</p>;
};

export const basicColumns = ([
  {
    field: 'name',
    headerName: 'Field label',
    sortable: false,
  },
  {
    field: 'key',
    headerName: 'API name',
    sortable: false,
  },
  {
    field: 'protectorType',
    headerName: 'Field type',
    renderCell: FieldTypeCell,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    sortable: false,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: EntityFieldType; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
    filterOperators: null,
  },
] as unknown) as ColumnDefinition[];

const FieldManagerTable = ({
  moduleName,
  onError,
}: {
  moduleName: string;
  onError: (error: VatixError) => void;
}): React.ReactElement => {
  const { entityFields } = useStore();

  const refreshRef = React.useRef<(() => void) | null>(null);

  const onRowClick: GridEventListener<'rowClick'> = (field: GridRowParams): void => {
    entityFields.setActiveField((field.row as unknown) as EntityFieldType);
  };
  const onRefreshCallback = (callback: () => void): void => {
    refreshRef.current = callback;
  };

  return (
    <>
      <Grid
        key={`field_manager_table_${moduleName}`}
        basicColumns={basicColumns}
        entity={`${moduleName} fields`}
        onError={onError}
        onRefreshCallback={onRefreshCallback}
        dataURL={`entities/${moduleName}/fields/`}
        onRowClick={onRowClick}
        showHeader={false}
        customNoRowsText="No fields have been created yet"
      />
      {entityFields.activeField !== null && (
        <CreateEditFieldModal
          entityType={moduleName}
          editedValues={entityFields.activeField}
          onClose={(refresh: boolean) => {
            entityFields.setActiveField(null);
            if (refresh) {
              refreshRef.current?.();
            }
          }}
        />
      )}
    </>
  );
};

export default observer(FieldManagerTable);
